.events{
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        max-width: 900px;
        height: 100%;
        padding: 0 20px;
        .banner{
            width: 100%;
            height: 200px;
            background-color: var(--background);
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            margin-bottom:20px;
            border-radius: 20px;
            overflow:hidden;
            opacity: 0;
            transition: 1s;


            .tl{
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% / 5);
                z-index: 1;
            }
            .br{
                position: absolute;
                bottom: 0;
                right: 0;
                width: calc(100% / 5);
                z-index: 1;
            }
            @media (max-width:500px) {
                .tl{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: calc(100% / 3);
                    z-index: 1;
                }
                .br{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: calc(100% / 3);
                    z-index: 1;
                }
            }
        }
        .banner.active{
            opacity: 1;
        }


    }

}