.review{
    .review-content{
        display:flex;
        gap:20px;
        height:fit-content;
        flex-direction: column;
        .col{
            justify-content: space-between;
            padding: 20px;
            box-sizing: border-box;
            gap: 20px;
        }
        .preview{
            display:flex;
            .full-event{

                border: 1px solid var(--lighterborder);
                box-shadow: var(--shadow);
            }
        }
        h4{
            margin:0;
        }
        .oie-acknowledgement{
            border-radius: 10px;
            display:flex;
            flex-direction: column;
            gap:10px;
            color:var(--text);
            .acknowledgement{
                display:flex;
                gap:10px;
                .check{
                    input{
                        display:none;
                    }
                    .checkmark{
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        border: 1px solid var(--lightborder);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        transition: all 0.3s;
                        iconify-icon{
                            opacity: 0;
                        }
                    }
                    input:checked + .checkmark{
                        background-color: var(--white);
                        border: 1px solid var(--green);
                        iconify-icon{
                            opacity: 1;
                            color: var(--green);
                            
                        }
                    }


                }
                label{
                    font-family: 'Inter';
                    font-weight:500;
                    -webkit-user-select: none;
                    user-select: none;
                    cursor: pointer;
                }
            }
        }
        .contact{
            color: var(--text);
            input{
                margin-top:10px;
            }
        }
    }

    .publish-container{
        display:flex;
        align-items: center;
        justify-content: center;
        width:100%;
        box-sizing: border-box;
        padding: 20px 0;
    }

    .publish{
        cursor:not-allowed;
        display:flex;
        align-items: center;
        justify-content: center;
        padding: 10px 150px;
        width:fit-content;
        position: relative;
        overflow: show;
        z-index: 1;
        animation: glint 3s infinite linear;
        border:1px solid var(--lighterborder);
        border-radius: 10px;
        background-color: var(--lighter);
        
        &::after{
            transition: all 0.5s;
            content: '';
            background-image: radial-gradient( circle farthest-corner at 10% 20%,  #FD1E86 17.8%, #FCD38C 100.2% );
            filter: blur(20px);
            width: 100%;
            height: 100%;
            z-index: -1;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }

    
        .gradient-cover{
            width:100%;
            height:100%;
            position: absolute;
            overflow: hidden;
            border-radius: 9px;
    
            img{
                top:0;
                width:130%;
                left:0;
                z-index: 1;      
                opacity: 0;  
                transition: all 0.5s;
            }
        }
    
    
        .info{
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: var(--text);
            z-index: 2;
            transition: all 0.5s;
            h1{
                color: var(--darkborder);
                transition: all 0.5s;
                margin:0;
                font-size:18px;
            }
        }
        &.active{
            cursor:pointer;
            background-color: var(--background);
            .info{
                h1{
                    color: var(--background);
                }
            }

            .gradient-cover img{
                top:0;
                width:130%;
                left:0;
                opacity: 1;
            }

            &:hover{
                border:1px solid transparent;
            }
        
            &:hover::after{
                opacity:1;
            }

        }
    }
    
    @keyframes glint {
        0% {
          box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 15px rgba(255, 255, 255, 0.8),
                      0 0 20px rgba(255, 255, 255, 0.8);
        }
        25% {
          box-shadow: 10px 0 20px rgba(255, 255, 255, 0.8), 15px 0 25px rgba(255, 255, 255, 0.8),
                      20px 0 30px rgba(255, 255, 255, 0.8);
        }
        50% {
          box-shadow: 0 10px 20px rgba(255, 255, 255, 0.8), 0 15px 25px rgba(255, 255, 255, 0.8),
                      0 20px 30px rgba(255, 255, 255, 0.8);
        }
        75% {
          box-shadow: -10px 0 20px rgba(255, 255, 255, 0.8), -15px 0 25px rgba(255, 255, 255, 0.8),
                      -20px 0 30px rgba(255, 255, 255, 0.8);
        }
        100% {
          box-shadow: 0 -10px 20px rgba(255, 255, 255, 0.8), 0 -15px 25px rgba(255, 255, 255, 0.8),
                      0 -20px 30px rgba(255, 255, 255, 0.8);
        }
      }
}