.rating-graph{
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 20px;
    width: 100%;

    .number-col{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height:100%;
        gap:5px;
        padding: 0 15px;
        p{
            font-size: 12px;
            margin: 0;
            color: var(--text);
            font-weight: 700;
            font-family: 'Inter';
        }
        .rating-score{
            display:flex;
            gap:5px;
            h3{
                font-size:22px;
                margin: 0;
                font-family: "Inter";
                color: var(--text)
            }
            img{
                width:27px;
                height:27px;
            }
        }
    }
    
    .ratings-col{
        display:flex;
        flex-direction: column;
        gap:5px;
        flex-grow: 1;
        padding-right:15px;
        
        .rating-row{
            display:flex;
            align-items: center;
            .bar{
                background-color: var(--border);
                height:10px;
                width: 100%;
                transition: width 1s;
                border-radius: 5px;
                .bar-fill{
                    background-color: var(--rating-yellow);
                    height:100%;
                    width: 0;
                    border-radius: 5px;
                }
            }
            .rating-stars p{
                font-size: 12px;
                margin: 0;
                color: var(--text);
                font-weight: 700;
                font-family: 'Inter';
                width:10px;
                margin-right:10px;
            }
        }
    }
}