.onboard{
    position: relative;
    overflow:hidden;
    height:100vh;
    width:100vw;
    display:flex;
    align-items: center;
    justify-content: center ;
    flex-direction: column;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.onboard.visible{
    opacity:1;
}

.onboard .content h2{
    font-family: 'Satoshi';
    color: var(--text);
    text-align: center;
    margin:0;
}

.onboard .yellow-red{
    position: absolute;
    top:0;
    right:0;
    pointer-events: none;
    z-index: -1;
}

.onboard .purple{
    position:absolute;
    bottom:0;
    left:0;
    pointer-events: none;
    z-index: -1;
}

.onboard .content{
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
    transition: all 0.5s ease-in-out;
    max-width: 400px;
    margin-bottom:10px;
}

.onboard .content .card-container{
    position: relative;
    width:100%;
}

.onboard .content .card-header::after{
    transition: all 0.5s;
    content: '';
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  #F2374C 17.8%, #F9E298 100.2% );
    filter: blur(0px);
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    filter: blur(20px);
    opacity: 1;
}

.onboard .content .text-input{
    padding:10px 20px;
    font-family: 'Inter';
    font-weight:600;
    color:var(--text);
    border:none;
    outline:none;
    background-color: var(--light);
    border-radius: 10px;
    font-size:15px;
}

.onboard .content .loader{
    margin-top:10px;
}

.onboard .content.going{
    opacity:0;
}

.onoard .content.gone{
    position: absolute;
}

.onboard .content.beforeOnboard{
    opacity:0;
}

.onboard .content p{
    max-width: 400px;
    margin:0;
    font-family: 'Inter';
    font-weight:500;
    color:var(--text);
    text-align: center;
    margin-bottom:10px;
}

.onboard .content .badge p{
    margin:0 7px;
    color:white;    
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
}

.onboard button{
    font-family: 'Satoshi';
    font-size: 15px;
    outline:none;
    border-radius: 8px;
    background-color: var(--red);
    border:none;
    padding:5px 20px;
    margin:10px;
    color: white;
    cursor:pointer;
    transition: all 0.5s;
}

.onboard .content button:hover{
    cursor:pointer;
    filter: brightness(0.9);
}

.onboard button.deactivated{
    background-color: var(--lightborder);
    pointer-events: none;
}

.onboard .logo{
    width:50px;
    height:50px;
}

.content .slider{
    background: linear-gradient(to right, #FA756D, #87AFF1);
    -webkit-appearance: none;
    appearance: none;
    height:10px;
    width:400px;
    border-radius: 5px;
    margin:0;
    box-sizing: border-box;
}


.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.sliderText{
    font-size:14px;
}

.sliderContainer{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap:10px; */
    width:400px;
}

.rangeText{
    display:flex;
    justify-content: space-between;
    width:100%;
}

.content .rangeText p{
    font-family: 'Satoshi';
    font-size: 16px;
    font-weight: bold;
    margin:0;
}

.rangeText .routine{
    color: var(--red) !important;
}

.rangeText .novelty{
    color: var(--blue) !important;

}

.sliderInput{
    display:grid;
    width:100%;
}

.thumb{
    height:15px;
    width:15px;
    border-radius:50%;
    background-color: #D9D9D9;
    position: absolute;
    align-self: center;
    pointer-events: none;
    transition: left 0.3s ease;
}

.onboard .username-input{
    position: relative;
    display: grid;
    margin-bottom:10px;
}

.onboard .status{
    position: absolute;
    bottom: -35px;
    align-self: center;
    justify-self: center;
}

.onboard .status p{
    font-size:14px;
    font-weight: 500;
    margin-bottom:2px;
}

.onboard .status div{
    display:flex;
    gap:8px;
    align-items: center;
}

.onboard .status img{
    width:15px;
    height:15px;
}

.onboard .checking p{
    color: var(--yellow) !important;
}

.onboard .available p{
    color: var(--green) !important;
}

.onboard .taken p{
    color: var(--red) !important;

}

.onboard .invalid p{
    color: var(--red) !important;
}


@media (max-width: 1000px) {
    .onboard .yellow-red,
    .onboard .purple{
        height:400px;
    }
}

@media (max-width: 500px){
    .onboard .yellow-red,
    .onboard .purple{
        height:300px;
    }

    .onboard{
        gap:0px;
    }

    .onboard button{
        margin:0;
    }

    .onboard .content{
        margin:15px;
        gap:10px;
    }

    .sliderContainer{
        width:100%;
    }

    .onboard .slider{
        width:100%;
        border-radius: 5px;
    }
    
    .onboard .content h2{
        font-size:20px;
    }

    .content p{
        font-size:16px;
        max-width: 100%;
    }

    .onboard .card-container{
        scale:0.9;
    }
}
