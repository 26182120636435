.classroom-component{
    position:absolute;
    height:100%;
    width:100%;
    /* padding: 20px 20px 40px 20px; */
    box-sizing: border-box;
    display:flex;
    flex-direction: column;
    background-color: var(--background);
    z-index: 1;
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.5s;
}

.classroom-component .z-index{
    z-index: 1;
    max-height:100%;
}

.classroom-component h1{
    font-family: 'Inter', sans-serif;
    font-size: 23px;
    font-weight: 800;
    color: var(--text);
    margin: 0;
    margin-right:10px
}

.classroom-component .image{
    width: 100%;
    height: 30%;
    max-height: 30%;
    top: 0;
    left: 0;
    border-radius: 19px 19px 0 0;
    overflow:hidden;
    display:flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap:5px;
    background: var(--lighterborder);
}

.classroom-component .image .open-image{
    position: absolute;
    bottom:13px;
    z-index: 10;
    display:flex;
    background-color: rgba(var(--text-rgba), 0.8);
    padding:3px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.5s;
    opacity:1;
}

.classroom-component .image .open-image.out{
    opacity:0;
}

.classroom-component .image .open-image img{
    width: 14px;
    height: 14px;
}

.classroom-component .image .open-image p{
    font-family: 'Inter';
    font-weight: 500;
    color: white;
    font-size: 14px;
    margin:0;
    margin-left:5px;
}

.image img:not(.open-image > img){
    min-width:100%;
    min-height:100%;
    /* object-fit:cover; */
    transition: all 0.5s;
}

.image img:not(.open-image > img).out{
    opacity:0;
}

.classroom-info{
    padding:15px 20px;
    max-height:70%;
    overflow-y:scroll;
    box-sizing: border-box;
}

.classroom-info p{
    font-family: 'Inter';
    color:var(--text);
    font-weight:400;
}

.back-to-results{
    margin-bottom:10px;
    display:flex;
    align-items:center;
    gap:5px;
    cursor:pointer;
    transform:translateX(-4px);
}

.back-to-results p{
    margin:0;
    font-family:'Inter';
    font-weight:500;
    color:var(--text);
    font-size:14px;

}

.header-row{
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
}

.bookmark-container{
    margin-top:5px;
}

.attributes{
    display:flex;
    gap:5px;
    margin-bottom:10px;
    flex-wrap: wrap;
}

.attribute{
    display:flex;
    align-items:center;
    justify-self: center;
    border-radius: 10px;
    padding: 3px 8px;
    font-family: 'Inter';
    font-weight:500;
    color:var(--darkborder);
    font-size: 13px;
    cursor: pointer;
    border: 1px solid var(--lighterborder);
    gap:5px
}

.attribute img{
    height:13px;
    width:13px;
}



.rating{
    display:flex;
    flex-direction: row;
    gap:5px;
    align-items: center;

}

.rating p{
    margin:0;
    font-size:14px;
    font-family:'Inter';
    font-weight:700;
}

.rating img{
    width:15px;
    height:15px;

}

.info-row{
    display:flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
    margin: 7px 0;
}


.classroom-component .ratings-preview{
    background-color: var(--lighter);
    border-radius: 10px;
    margin-top:10px;
    padding-bottom:15px;
    button{
        background-color: var(--darkborder);
        outline: none;
        border: none;
        background-color: var(--darkborder);
        padding: 6px 20px;
        width: calc(100% - 30px);
        margin: 0 15px;
        border-radius: 8px;
        color: white;
        font-family: 'Inter';
        font-weight: 500;
        font-size: 13px;
        cursor: pointer;
        margin-top:10px;
    }
    .ratings-header{
        
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        padding: 10px 15px 0 15px;

        h2{
            color: var(--text);
            font-family: 'Inter';
            font-size:16px;
            margin:0;
            
        }
        button{
            outline: none;
            border: none;
            background-color: var(--darkborder);
            border-radius: 5px;
            padding: 5px 10px;
            color: white;
            font-family: 'Inter';
            font-weight: 500;
            font-size: 12px;
            cursor: pointer;
            width:fit-content;
            margin:0;
        }
    }
    
}

.classroom-component .check-in{
    width: calc(100% - 30px);
    margin:15px;
    position: absolute;
    bottom:0;
    left:0;
    border-radius: 10px;
    border: 1px solid var(--lighterborder);
    box-shadow: var(--shadow);
    padding:15px;
    box-sizing: border-box;
    background-color: white;
}

.classroom-component .check-in .free-until,
.classroom-component .check-in .class-until{
    font-weight:500;
    /* margin: 5px 0 10px 0; */
    font-size:14px;
    display:flex;
    align-items:center;
    gap:5px;
    color:var(--green);
    padding:0 15px;
    position: relative;
    font-family: 'Inter';
}
    
.classroom-component .check-in .class-until{
    color:var(--red);
}


.classroom-component .button-container{
    display: flex;
    gap:10px;
    margin-top:10px;
    width:100%;
}

.classroom-component .check-in button{
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 650;
    color: white;
    background-color: var(--green);
    border: none;
    border-radius: 8px;
    padding: 8px 20px;
    flex-grow:1;
    cursor: pointer;
    transition: all 0.3s;

}

.classroom-component .check-in .check-in-button{
    /* position: relative; */
}

.classroom-component .check-in .check-in-button::after{
    transition: all 0.5s;
    content: '';
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  var(--green) 17.8%, var(--developerBlue) 100.2% );
    filter: blur(0px);
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.classroom-component .out::after{
    transition: all 0.5s;
    content: '';
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  var(--green) 17.8%, var(--developerBlue) 100.2% );
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    filter: blur(20px);
    opacity: 1;
}


.classroom-component .check-in .check-in-button:hover::after {
    filter: blur(20px);
    opacity: 1;
  }

  @keyframes move {
    0% {
      background-position: 10% 20%;
    }
    100% {
      background-position: 90% 80%;
    }
  }
  

.classroom-component .check-in button.out{
    background-color: var(--red);
}

.classroom-component .check-in button:hover{
    cursor:pointer;
}

.classroom-component .check-in button:disabled{
    background-color: var(--lighterborder);
    color: var(--border);
    cursor: not-allowed;
}

.classroom-component button.schedule-button{
    background-color: var(--blue);
}

.check-in p{
    font-size: 13px;
    font-family: 'Inter';
    font-weight: 500;
    color: var(--border);
    margin: 0;
    width:100%;
    text-align: center;
    margin-top:10px;

}

.classroom-component .rating-num{
    font-size: 12px;
    font-family: 'Inter';
    font-weight:500;
    color: var(--text);
    position:relative;
    cursor: pointer;
}


.classroom-component .rating-num::after{
    content: '';
    position:absolute;
    bottom:-1px;
    height:1px;
    width:100%;
    left:0;
    border-radius:5px;
    background-color: var(--text);
}

.classroom-component .add-rating{
    display:flex;
    padding: 2px 10px;
    background-color: var(--light);
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    border:none;
}

.classroom-component .add-rating:hover{
    background-color: var(--lighterborder);
}


.classroom-component .add-rating p{
    font-size:12px;
    font-weight:500;
}



/* .check-in-button::after{
    content: "check-in feature coming soon";
    font-size: 10px;
    color: var(--text);
    font-family: 'Inter';
    font-weight: 500;
    position: absolute;
    width: calc(100% - 80px);
    top:-40px;
    left:0;
    border-radius: 5px;
    margin: 0 30px;
    padding: 5px 10px;

    border: 1px solid var(--lighterborder);
} */


.whole-page{
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;  
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transition: background-color 0.5s ease, opacity 0.5s ease;
}
 
.whole-page.in{
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 1;
}

.whole-page.out {
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    pointer-events: none;
}

.img-pop-up img{
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    height: 400px;
    width: 600px;
    position: fixed; 
    z-index: 9999; 
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 1;
    transition: opacity 0.5s ease;
}

.img-pop-up.in img{
    opacity: 1;
}

.img-pop-up.out img{
    opacity: 0;
    pointer-events: none;
}

@media (max-width:500px) {
    .classroom-component .check-in button{
        font-size:15px;
    }

    .img-pop-up img{
        width:90%;
        height:auto;
    }
}



.btn-shine {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 12px 48px;
    color: #fff;
    background: linear-gradient(to right, #9f9f9f 0, #fff 10%, #868686 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    white-space: nowrap;
    font-family: "Poppins", sans-serif;
  }
  @-moz-keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 180px;
    }
    100% {
      background-position: 180px;
    }
  }
  @-webkit-keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 180px;
    }
    100% {
      background-position: 180px;
    }
  }
  @-o-keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 180px;
    }
    100% {
      background-position: 180px;
    }
  }
  @keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 180px;
    }
    100% {
      background-position: 180px;
    }
  }
  