.checked-in-component{
    display:flex;
    width:100%;
    justify-content: flex-end;
    align-items: center;
    gap:5px;

    h3{
        font-size: 13px;
        font-family: 'Inter';
        font-weight: 500;
        color: var(--darkborder);
        margin: 0;
        text-align: center;
        margin-top: 10px;
        margin:0;
    }

    img.user{
        height:20px;
        width:20px;
        max-height:20px;
        max-width:20px;
        border-radius: 50%;
        background-color: white;
        
    }

    .user-images{
        display:flex;
        gap:0px;
        margin-left:5px;


        .user-image{
            display:flex;
            justify-content: flex-end;
            height:20px;
            width:15px;
        }
    }



    
}


