.admin{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .content{
        display:flex;
        width:calc(100% - 30px);
        max-width: 600px;
        flex-direction: column;
        gap:10px;

        .banner{
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width:100%;
            height:100px;
            border-radius: 20px;
            position: relative;
            background-color: transparent;
            h1{
                z-index: 2;
            }
            @media (max-width:500px) {
                h1{

                    font-size:20px;
                }
            }

            .tr{
                position: absolute;
                top: 0;
                right: 0;
                width: calc(100% / 2);
                z-index: 1;
            }
            .bl{
                position: absolute;
                bottom: 0;
                left: 0;
                width: calc(100% / 2);
                z-index: 1;
            }
        }

        .options{
            display:flex;
            justify-content: center;
            align-items: center;
            gap:10px;
            margin-top:10px;

            button{
                padding: 5px 10px;
                border-radius: 5px;
                border: 1px solid var(--lightborder);
                background-color: var(--background);
                color: var(--text);
                font-family: 'Inter';
                font-weight: 500;
                font-size: 14px;
                cursor: pointer;
                outline:none;
                -webkit-tap-highlight-color: transparent;
                
            }
            button.selected{
                background-color: var(--red);
                border: 1px solid var(--red);
                color:var(--background);
            }
        }
    }
    
}
