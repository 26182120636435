.edit-attributes{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    background-color: white;
    padding:35px 20px 20px 20px;
    border-radius: 14px;
    align-self: center;
    justify-self: center;
    top:20%;
    border: 2px solid var(--lightborder);
    box-sizing: border-box;
}

.edit-attributes .x{
    position:absolute;
    top:15px;
    right:15px;
    margin:0;
    padding:0;
}

.edit-attributes .attribute{
    font-size:18px;
    justify-content: space-between;
}

.attribute-info{
    display:flex;
    flex-direction: row;
    gap:5px;
    align-items: center;
    justify-content: center;

}
.edit-attributes .attribute img{
    height:18px;
    width:18px;
}

.input{
    width:70%;
    outline:none;
    border: 2px solid var(--lightborder);
    border-radius: 5px;
    padding: 5px;
    font-family: 'Inter';
    font-size: 18px;
    font-weight:500;
    color:var(--border);
    box-sizing: border-box;
    height:35px;
}

.input::placeholder{
    color:var(--lighterborder);

}

.add{
    width:30%;
    height:35px;
    margin:0;
    outline:none;
    border: 2px solid var(--lightborder);
    color: var(--border);
    border-radius: 5px;
}

.addNew{
    display:flex;
    margin-top:20px;
}

.save{
    width:100%;
    height:35px;
    margin:0;
    outline:none;
    border: 2px solid var(--lightborder);
    color: var(--border);
    border-radius: 5px;
    margin-top:10px;
}

.image-change{
    margin-top:10px;
    width:100%
}