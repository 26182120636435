.calendar-event{
    background-color: #FBD8D6;
    border-left: 4px solid var(--red);
    margin: 2px;
    border-radius: 4px;
    box-shadow: var(--shadow);
    overflow: hidden;
    z-index: 4;
    height:100%;
    
    .event-time {
        font-size: 0.75em;
        color: var(--red);
        width:100%;
        padding: 5px;
    }

    .event-content{
        padding: 8px;
        padding-top: 0px;
        .event-details {
            display: flex;
            flex-direction: column;
            gap: 8px;
            font-size: 0.8em;
            color: #555;
            margin-bottom: 4px;
            .event-type{
                padding: 2px 4px;
                border-radius: 4px;
                background-color: rgba(250,117,109,.3);
                color: var(--red);
                &.campus{
                    background-color: rgba(110,178,95,0.6);
                    color: #35714E;
                
                }

            }
        }
        .event-name {
            font-weight: bold;
            font-size: 0.9em;
            margin-bottom: 4px;
        }
    }

}