.sort-row{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    gap:5px;
    width:100%;
    padding:0 30px;
    margin-top:10px;
    box-sizing: border-box;
    position: relative;

    .free-now{

    }
}

/* .sort-row div:hover{
    cursor: pointer;
} */    

.sort-row .tags,
.sort-row .sort-by,
.sort-row .more,
.sort-row .free-now{
    border: 1px solid var(--border);
    border-radius: 10px;
    display:flex;
    justify-content: center;
    align-items: center;
    gap:5px;
    padding:3px 10px;

    font-size: 12px;
    font-weight: 500;
    color: var(--text);
    font-family:'Inter',sans-serif;
    background-color: var(--background);
    outline:none;
    border:1px solid var(--border);
    border-radius: 10px;
    box-shadow:var(--shadow);
    transform: all 0.3s;

    cursor:pointer;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* Internet Explorer/Edge */
    user-select: none;         /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */

}

.free-now.active{
    background-color: var(--red);
    color:var(--background);
    border: 1px solid var(--red);
}

.sort-row .tags img,
.sort-row .sort-by img,
.sort-row .more img{
    width:12px;
    height:12px;
    pointer-events: none;
}

.sort-row .tags p,
.sort-row .sort-by p,
.sort-row .more p{
    font-size: 12px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin:0;
    pointer-events: none;

}

.sort-row .selected{
    background-color: var(--red);
    color: white;
    border: 1px solid var(--red);
}

.sort-row .selected p{
    color: white;
}

.sort-popup{
    position: absolute;
    top: 30px;
    left: 20px;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid var(--border);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px 20px;
    z-index: 100;
    height: 320px;
    opacity: 1;
    width:calc(100% - 40px);
    overflow-x:hidden;
    justify-content: space-between;
    max-width:300px;
}

.sort-popup button{
    margin-top:0;
}

.sort-popup .heading h1{
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    margin:0;
}

.sort-popup .heading p{
    margin:0;
    color: var(--red);
    font-size:16px;
    font-weight:450
}

.heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.option{
    display:flex;
    padding:10px;
    gap:10px;
    align-items: center;
    background-color: var(--background);
    border:1px solid var(--lightborder);
    border-radius:7px;
    box-sizing: border-box;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* Internet Explorer/Edge */
    user-select: none;         /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */
    transition: all 0.2s;
    cursor: pointer;
    height:30px;
}

.option img{
    height:20px;
    width:20px;
}

.option p{
    margin:0;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight:500;
    color:var(--border);
}

.option.selected{
    background-color: var(--red);
    border:none;

}

.option.selected p{
    color:white;
}