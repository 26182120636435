.event-component.create{
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
    overflow: show;
    z-index: 1;
    animation: glint 3s infinite linear;
    transition: border 0.3s ease;
    min-width:250px;
    
    &::after{
        transition: all 0.5s;
        content: '';
        background-image: radial-gradient( circle farthest-corner at 10% 20%,  #FD1E86 17.8%, #FCD38C 100.2% );
        filter: blur(20px);
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }

    &:hover{
        border: 1px solid transparent;
    }

    &:hover::after{
        opacity:1;
    }

    .gradient-cover{
        width:100%;
        height:100%;
        position: absolute;
        overflow: hidden;
        border-radius: 9px;

        img{
            top:0;
            width:130%;
            left:0;
            z-index: 1;      
            opacity: 0;  
            transition: all 0.5s;
        }
    }

    &:hover .gradient-cover img{
        top:0;
        width:130%;
        left:0;
        opacity: 1;
    }

    .info{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size:20px;
        color: var(--text);
        z-index: 2;
        transition: all 0.5s;
        h1{
            color:var(--text);
            transition: all 0.5s;
            margin: 0;

        }
        iconify-icon{
            transition: all 0.5s;
        }
    }

    &:hover .info{
        color: var(--background);
        iconify-icon{
            color: var(--background);
        }
        h1{
            color: var(--background);
        }
    }
}

@keyframes glint {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 15px rgba(255, 255, 255, 0.8),
                  0 0 20px rgba(255, 255, 255, 0.8);
    }
    25% {
      box-shadow: 10px 0 20px rgba(255, 255, 255, 0.8), 15px 0 25px rgba(255, 255, 255, 0.8),
                  20px 0 30px rgba(255, 255, 255, 0.8);
    }
    50% {
      box-shadow: 0 10px 20px rgba(255, 255, 255, 0.8), 0 15px 25px rgba(255, 255, 255, 0.8),
                  0 20px 30px rgba(255, 255, 255, 0.8);
    }
    75% {
      box-shadow: -10px 0 20px rgba(255, 255, 255, 0.8), -15px 0 25px rgba(255, 255, 255, 0.8),
                  -20px 0 30px rgba(255, 255, 255, 0.8);
    }
    100% {
      box-shadow: 0 -10px 20px rgba(255, 255, 255, 0.8), 0 -15px 25px rgba(255, 255, 255, 0.8),
                  0 -20px 30px rgba(255, 255, 255, 0.8);
    }
  }