
.time-results{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width:calc(98% - 10px);
    position:relative;
    border:none;
    list-style-type: none;
    box-sizing: border-box;    
    padding:0;
    max-height:100%;
    height:100%;
    overflow:scroll;
    margin-top:10px;
    margin-left:10px;
    border-radius: 0 0 20px 20px;
}

.no-results{
    height:100%;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.no-results p {
    padding-bottom:50px;
    font-family: 'Inter';
    font-weight:500;
}

