:root{
    --search-width: calc(100% - 60px);
    --search-height: 50px;
    --search-font:'Inter';
    --search-font-size: 17px;
    --search-font-weight:450;

}

.search-container ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    width:100%;
    max-height: 200px;
    position: absolute;
    border: 1px solid var(--border);
    z-index: 8;
    transform:translateY(-15px);
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #FBFBFB;
    overflow: scroll;
    
}

.chosen{
    background-color: #F7F7F7;
    border-radius: 5px;
    /* border-left:4px solid #FA756D;
    border-right: 4px solid #F7F7F7; */

}

.chosen::before{
    content: "";
    background-color: #FA756D;
    position: absolute;
    left: 0px;
    top: 0px;
    height:100%;
    width:6px;
    border-radius: 0 5px 5px 0;
}


.search-container li {
    background-color: #FBFBFB;
    /* border-bottom: 1px solid #B0AFAF; */
    font-family: var(--search-font);
    font-size: var(--search-font-size);
    font-weight: var(--search-font-weight);
    color: #414141;
    padding: 5px 20px;
    box-sizing: border-box;
    outline: none;
    text-align: left;
    border-radius: 10px;
    position:relative;
    transition: all 0.5s;
    width:100%;
}

.search-container li:hover {
    background-color: #f0f0f0;
    cursor: pointer;
    
}

.search-container{
    width:var(--search-width);
    position:relative;
    margin: 30px 30px 0 30px;
    height:fit-content;
    z-index: 2;
}

.search-bar{
    width:100%;
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid #B0AFAF;
    font-family: var(--search-font);
    font-size: var(--search-font-size);
    font-weight: var(--search-font-weight);
    color: #414141;
    padding: 7px 40px 7px 20px;
    box-sizing: border-box;
    outline: none;
    text-align: left;
    z-index: 10;
    position:relative;
    transition:all 0.3s;
    white-space: nowrap;
    overflow:hidden;
}


.non-match{
    color: #929292;
}

.no-results:hover{
    background-color: white !important;
}

.spacer{
    height:15px;
}

.x-container{
    height:100%;
    position:absolute;
    display:flex;
    align-items: center;
    justify-content: center;
    right:15px;
    top:0;
}

.x{
    height:12px;
    width:12px;
    z-index: 11;
    transform: scale(1.1);
    padding: 4.2% 0 0 0;
}
.x:hover{
    cursor: pointer;
    transform: scale(1.2);
}

.search-container .result{
    pointer-events: none;
}

.center{
    text-align: center;
}

.noPointer{
    pointer-events: none;
}

.shadow{
    position:absolute;
    top: 0;
    left: 0;
    z-index: 9;
    pointer-events: none;
    background-color: white;
    color: #6C6C6C;
    width:100%;
    border-radius: 10px;
    border: 1px solid #B0AFAF;
    font-family: var(--search-font);
    font-size: var(--search-font-size);
    font-weight: var(--search-font-weight);
    padding: 7px 40px 7px 20px;
    box-sizing: border-box;
    outline: none;
    text-align: left;
    display:flex;
    align-items: center;
    justify-content: left;
    white-space: nowrap;
    overflow:hidden;
}

.shadow text::after{
    content: "hello";
    position: absolute;
    top: 0;
    right:0 ;
    width: 100%;
    height:100%;
    background-color: white;
    opacity: 0.5;
    z-index: 11;
}

.tab{
    transform:translateY(1px);
    margin-left:10px;
}

.disappear{
    display:none;
}

.white{
    color:white;
}