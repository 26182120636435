.Calendar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity:1;
    transition:0.2s all;
}

.Calendar.loading{
    opacity:0.5;
}

.Calendar-header{
    display:flex;
    flex-direction: row;
    width:600px;
    align-items: center;
    margin-bottom:10px;
}

.Calendar-header.weekend{
    width:850px;
}

.Calendar-header.date{
    margin-bottom:0;
}


.Calendar-header p{
    min-width: 20%;
    color: #B0AFAF;
    font-family: 'Satoshi';
    font-size: 14px;
    font-weight: normal;
    text-align: center;
}

.Calendar-header.weekend p{
    min-width: 14.28%;
}

.currentDay{
    background-color: var(--red);
    color: white !important;
    border-radius: 10px;
    padding: 5px 5px;
    box-sizing:border-box;
}

.weekend .currentDay{
    background-color: var(--background-color);
    color:#B0AFAF !important;

}
/* .loading{
    width:825px;
    height:550px;
    border-radius: 20px;
} */


@media screen and (max-width: 1150px){
    .Calendar-header{
        width:500px;
    }

    .Calendar-header.weekend{
        width:700px;

    }
}


@media screen and (max-width: 875px){
    .Calendar-header{
        transform:translateX(25px);
    }
}

@media(max-width: 600px){
    .Calendar-header{
        width:500px;
    }
    .Calendar-header p{
        font-size: 10px;
    }
}

@media(max-width: 500px){
    .Calendar-header{
        width:400px;
    }
    .Calendar-header p{
        font-size: 10px;
    }
}

@media(max-width: 400px){
    .Calendar-header{
        width:300px;
        transform:translateX(18px);
    }
    .Calendar-header p{
        font-size: 10px;
    }
}