

.collapsible-header{
    display: none;
}

.collapsible-header.open {
    transform: rotate(0deg);
}

.history-content.open {
    max-height: 0px;
    overflow: hidden;
}

.history-content{
    display:flex;
    flex-direction: column;
    overflow-y:scroll;
    height:calc(100% - 35px);
    align-items: center;
    padding: 5px 10px;
    box-sizing: border-box;
}

.study-history.open{
    max-height: 34px;
    overflow: hidden;
}

@media (max-width:500px) {
    .collapsible-header{
        margin-left: auto;
        margin-right: 10px;
        border: none;
        outline: none;
        background-color: var(--white);
        transform: rotate(180deg);
        display: block;
    }
}
