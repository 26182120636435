.user-rating{
    display: flex;
    box-sizing: border-box;
    margin:5px;
    justify-content: flex-start;
    width:100%;
    position: relative;
    flex-direction: column;
    padding:10px;
    gap:5px;

    .content-row{
        display: flex;
        box-sizing: border-box;
        justify-content: flex-start;
        width:100%;
    }

    .pfp-col{
        width:55px;

        img{
            width:45px;
            height:45px;
            background-color: white;
            border-radius: 50%;
        }
    }

    .info-col{
        width: calc(100% - 60px);
        display:flex;
        flex-direction: column;
        gap:3px;
        h3{
            font-size: 15px;
            margin: 0;
            font-family: 'Inter';
            font-weight:700;
            color: var(--text);
            flex-grow: 0;
        }

        .info-row{
            display:flex;
            margin:0;
            max-width:100%;

            p.comment{
                font-size:13px;
                text-wrap: wrap;
                width: 100%;
                word-break:break-word;
                -webkit-hyphens: auto;
                hyphens: auto;
                font-family:'Inter';
                font-weight:500;
                margin-left:5px;
            }
        }

        .info-row.rating{
            display:flex;
            margin:0;
            gap:5px;
            width:100%;


            p.score{
                font-size: 13px;
                margin: 0;
                font-family: 'Inter';
                font-weight:800;
                color: var(--text);
            }
            
            img{
                width:14px;
                height:14px;
            }
        }
    }

}

.user-rating.own{
    margin: 30px 0 10px 0;
    background-color: var(--lighter);
    border-radius: 10px;

}

.user-rating.placeholder{
    height:70px;
    align-items: center;
    justify-content: center;
}

.user-rating.own:after{
    content: "Your Rating";
    font-size:12px;
    font-family: 'Inter';
    font-weight:500;
    color: var(--background);
    top:-18px;
    left:0;
    position: absolute;
    background-color: var(--red);
    padding: 2px 5px 20px 5px;
    border-radius: 5px;
    z-index: -1;
}