@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

.form, .form-content, .email-form{
    display:flex;
    flex-direction:column;
    justify-content:center;
    gap:20px;
    width:300px;
    align-items: center;
    font-family: Inter, sans-serif;
    height:100%;
}

.email-form{
    opacity: 1;
    position: relative;

    .login-button{
        width:100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        gap:10px;
        position: absolute;
        top:0;
        left:0;
    }
    .form-content{
        width:100%;
        opacity:0;
        transition: all 0.3s ease;
        pointer-events: none;
    }

    &.disappear-show .form-content{
        opacity:1;
        pointer-events: all;
    }
}

.show-email{
    transition: all 0.3s ease;

    &.disappear-show{
        opacity:0;
        pointer-events: none;
    }
}

.form input{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding: 25px 20px;
    font-size: 14px;
    font-family: Inter, sans-serif;
    background-color: #F5F5F5;
    color: #414141;
    box-sizing: border-box;
    outline: none;
    border:none;
    position: relative;
    font-weight:500;
}

.form input::placeholder{
    color: #B1B1B1;
}

.email p,
.password p,
.username p {
    font-family:Inter, sans-serif;
    font-size:13px;
    font-weight: 500;
    color: #414141;
    margin: 0 0 5px 7px;
}

.email,
.password,
.username{
    position: relative;
    width:100%;
}


.already{
    font-size: 13px;
    font-family: Inter, sans-serif;
    color: #414141;
    width:100%;
    text-align: left;
    margin: 0 0 0 7px;

    &.disappear-show{
        opacity:0;
        pointer-events: none;
    }
}

.google{
    background-color: #1E1E1E;
    color: #FFFFFF;
    margin-top:0;
    pointer-events: all;
}

.divider{
    display:flex;
    flex-direction: row;
    width:80%;
    justify-content: center;
    align-items: center;
}

.divider hr{
    width: 100%;
    height: 2.1px;
    background-color: #DDDCDC;
    margin: 0;
    border:none;
    border-radius: 3px;

}

.divider p{
    font-family:Inter, sans-serif;
    font-size:13px;
    font-weight: 500;
    color: #414141;
    margin: 0 10px;
}

.google-button{
    width:100% !important;
} 

.error{
    font-family: Inter, sans-serif;
    position: relative;
    font-size:14px;
    padding: 10px 15px;
    font-weight:500;
    color: #FD5858;
    background-color: rgba(250, 117, 109, 0.16);
    border-radius: 10px;
    box-sizing: border-box;
    width:100%;
    display:flex;
    gap:10px;
    overflow: hidden;
    // padding-bottom: 20px;
}

.error img{
    width: 20px;
    height: 20px;
}

.error::before{
    content:'';
    position:absolute;
    top:0;
    left:0; 
    height:100%;
    background-color: #FD5858;
    width:7px;
    opacity:0.8;
    border-radius: 15px 0 0 15px;
}

@media (max-width: 500px) {
    .form, .form-content, .email-form{
        width:100%;
    }
}