@font-face {
    font-family: 'Satoshi';
    font-weight: bold;
    src: url(Satoshi-Black.otf);
}   

@font-face {
    font-family: 'Satoshi';
    font-weight: normal;
    src: url(Satoshi-Bold.otf);
}

@font-face{
    font-family:'Inter';
    src:url(Inter-VariableFont_opsz\,wght.ttf);
    font-weight:100 900;
    font-style:normal;
}

