:root{
    --club-background: #F4F4F5;
    --club-dark:rgba(9, 9, 11, .05);
}


.club-dash{
    overflow: hidden;
    height:100vh;
    width:100vw;
    display:flex;
    background-color: var(--club-background);
    z-index: 0;
    .dash-left{ 
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top:15px;
        box-sizing: border-box;
        animation: show 1s forwards;
        z-index: 10;
        .logo{
            position: relative;
            -webkit-user-select: none;
            user-select: none;
            width:90%;
            display:flex;
            align-items: center;
            gap: 5px;
            background-color: var(--background);
            height:fit-content;
            padding: 5px 8px;
            box-sizing: border-box;
            border-radius: 8px;
            cursor: pointer;
            transition: all 0.5s;

            &>*{
                transition: all 0.6s;
            }
            img{
                height:22px;
                width:22px;
            }
            h1{
                font-family: 'Inter';
                font-size:15px;
                width:120px;
                overflow: hidden;
                text-overflow: ellipsis; 
                white-space: nowrap;
                margin: 0;
            }
            .dropdown{
                position: absolute;
                top:calc(100% + 5px);
                left:0px;
                width:fit-content;
                background-color: var(--background);
                border-radius: 8px;
                overflow:hidden;
                padding: 5px;
                box-sizing: border-box;
                box-shadow: var(--shadow);
                .drop-option{
                    padding: 5px 12px 5px 8px;
                    cursor: pointer;
                    transition: all 0.2s;
                    display: flex;
                    gap: 5px;
                    border-radius: 5px;
                    align-items: center;
                    -webkit-user-select: none;
                    user-select: none;
                    p{
                        font-family: 'Inter';
                        font-weight: 600;
                        color:var(--text);
                        font-size: 15px;
                        transition: all 0.2s;
                        text-wrap: nowrap;
                    }
                    &:hover{
                        background-color: var(--club-dark);
                    }
                    &.selected{
                        background-color: var(--red-transparent);
                        &:hover{
                            background-color: var(--red-transparent);
                        }

                    }
                }
                .create-org{
                    width:100%;
                    margin-top:5px;
                    padding: 5px 10px;
                    p{
                        font-family: 'Inter';
                        font-weight: 600;
                        color:var(--text);
                        font-size: 13px;
                        transition: all 0.2s;
                    }
                }
            }
        }
        .nav{
            width:100%;
            display: flex;
            padding:0 5px;
            box-sizing: border-box;
            margin-top:20px;
            flex-direction: column;
            
            ul{
                list-style: none;
                box-sizing: border-box;
                padding: 0 8px;
                display: flex;
                flex-direction: column;
                gap: 5px;
                li{
                    display:flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap:10px;
                    padding:5px 10px;
                    cursor: pointer;
                    border-radius: 10px;
                    transition: all 0.2s;
                    -webkit-user-select: none;
                    user-select: none;


                    img, iconify-icon{
                        width:20px;
                        height:20px;
                        font-size:20px;
                        color: var(--text);
                        transition: all 0.2s;
                    }

                    p{
                        font-family: 'Inter';
                        font-weight: 600;
                        color:var(--text);
                        font-size: 14px;
                        transition: all 0.2s;
                    }

                    &:hover{
                        background-color: var(--club-dark);
                    }

                    &.selected{
                        // background-color: var(--background);
                        // border-radius: 10px;
                        // border: 1px solid var(--lightborder)
                        p{
                            color: var(--red);
                        }
                        iconify-icon{
                            color: var(--red);
                        }
                        background-color: var(--red-transparent);
                        &:hover{
                        }
                    }

                }
            }
        }
        &.hidden{
            animation: hide 1s forwards;
        }
    }
    .dash-right{
        position: relative;
        flex-grow: 1;
        display:flex;
        background-color: var(--background);       
        max-width: calc(100% - 220px); 
        transition: all 1.1s;
        .expand{
            position: absolute;
            bottom:20px;
            right:20px;
            background-color: var(--background);
            border-radius: 50%;
            padding: 5px;
            box-shadow: var(--shadow);
            display:flex;
            cursor: pointer;
            box-sizing: border-box;
            border: 1px solid var(--lightborder);
            font-size:20px;
            color: var(--text);
            &:hover{
                font-size:22px;
            }
            z-index: 1;
            
        }

        &::after{
            content: '';
            position: absolute;
            width:100%;
            height:100%;
            background-color: var(--background);
            top:0;
            left:0;
            z-index: 0;
            border-radius: 20px;
            transition: all 1s;
        }
        &.maximized{
            max-width: 100%;
        }
        &.minimized::after{
            transform:scale(1);
        }
        &.maximized::after{
            transform:scale(1.2);
        }

    }
    &.loading{
        .dash-left{
            min-width:220px;
            opacity: 1 !important;
            .logo{
                &>*{
                    opacity: 0;
                }
                background-color: var(--lightborder);
            }
        }
    }
}


/* Showing animation: width first, then opacity */
@keyframes show {
    0% {
      width: 10px;
      opacity: 0;
    }
    80% {
      width: 220px;
      opacity: 0;
    }
    100% {
      width: 220px;
      opacity: 1;
    }
  }
  
  /* Hiding animation: opacity first, then width */
  @keyframes hide {
    0% {
      width: 220px;
      opacity: 1;
    }
    20% {
      width: 220px;
      opacity: 0;
    }
    100% {
      width: 10px;
      opacity: 0;
    }
  }
