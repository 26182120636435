.whole_page{
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;  
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transition: background-color 0.5s ease, opacity 0.5s ease;
    font-size: 13px;
    font-weight: 400;
    color: black;
}

.classroom-name{
    padding: 8px 20px;
    background-color: var(--lighter);
    border: none;
    border-radius: 10px;
    font-family: Inter, sans-serif;
    cursor: pointer;
    text-align: center;
    width: 48%;
    margin-bottom: 15px;
}

.whole_page textarea{
    padding: 8px 20px;
    background-color: var(--lighter);
    border: none;
    border-radius: 10px;
    font-family: Inter, sans-serif;
    width: 75%;
    height: 65%;
}

.whole_page.in{
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 1;
}

.whole_page.out {
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    pointer-events: none;
}


.pop_up{
    box-sizing: border-box;
    border-radius:10px;
    display:flex;
    padding: 35px 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: var(--background );
    max-height: 400px;
    max-width: 500px;
    position: fixed; 
    border-radius: 8px; 
    gap: 3%;
    opacity: 0;
    z-index: 9999; 
    transition: opacity 0.5s ease;
    width:calc(100% - 60px);
    height:calc(100% - 60px);
    /* margin: 0 30px;  */
    box-sizing: border-box;
}
.pop_up.in {
    opacity: 1;
}

.pop_up.out {
    opacity: 0;
    pointer-events: none;
}

.pop_up .loader{
    height: 15%;
}

.whole_page h1{
    font-size: 20px;
    text-align: center;
    margin-left: 0;
    padding-left: 0;
}

.send-button{
    margin-top: 15px;
    padding: 6px 60px;
    background-color: var(--lightborder);
    color: white;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.3s;
    font-family: Inter, sans-serif;
    font-weight:700;
}

.send-button.alt {
    background-color: var(--red);
}

.send-button.alt:hover {
    filter: brightness(0.9);
}