
@mixin shimmer-effect($angle: 130deg, $duration: 2s) {
    position: relative;
    overflow: hidden;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -150%;
      width: 150%;
      height: 100%;
      background-image: linear-gradient(
        $angle,
        rgba(255, 255, 255, 0) 25%,   /* Start transparent earlier */
        rgba(255, 255, 255, 0.3) 80%, /* Peak opacity reduced */
        rgba(255, 255, 255, 0) 75%  
      );
      animation: shimmer $duration infinite;
    }
  }
  
  @keyframes shimmer {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(150%);
    }
  }
  

.new-badge{
    position: relative;
    overflow:hidden;
    height:100vh;
    width:100vw;
    display:flex;
    align-items: center;
    justify-content: center ;
    flex-direction: column;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    &.visible{
        opacity:1;
    }

    .content{
        max-width:400px;
        margin:0 50px;

        h1,h2,p{
            transition: opacity 0.5s ease-in-out;
        }

        & > div{
            display:flex;
            flex-direction: column;
            align-items: center;
            & > h2,p{   
                font-family: 'Satoshi';
                color: var(--text);
                text-align: center;
            }
    
            & > p{
                font-weight:500;
                font-family: 'Inter';
            }
        }

        button{
            font-family: 'Satoshi';
            font-size: 15px;
            outline:none;
            border-radius: 8px;
            background-color: var(--red);
            border:none;
            padding:5px 20px;
            margin-top: 20px;
            color: white;
            cursor:pointer;
            transition: all 0.5s;
        }
        
        button:hover{
            cursor:pointer;
            filter: brightness(0.9);
        }

        .mock-badge{
            font-family: 'Inter';
            font-size: 40px;
            color:white;
            padding:10px 28px;
            border-radius: 20px;
            font-weight:800;
            margin-bottom:20px;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
            @include shimmer-effect(130deg,2s );
            //box shadow red
            // box-shadow: 4px 4px 4px #D51F2B;

            &.visible{
                opacity: 1;
            }
        }
        .card-header{
            margin-top:20px
        }
    }
    .claimed{
        animation: fadeIn 0.5s ease-in-out;
    }
}

.invis{
    opacity:0;
}

.new-badge .yellow-red{
    position: absolute;
    top:0;
    right:0;
    pointer-events: none;
    z-index: -1;
}

.new-badge .purple{
    position:absolute;
    bottom:0;
    left:0;
    pointer-events: none;
    z-index: -1;
}


@media (max-width: 1000px) {
    .yellow-red,
    .purple{
        height:400px;
    }
}

@media (max-width: 500px){
    .yellow-red,
    .purple{
        height:300px;
    }
}

