.create-org{
    .yellow-red{
        height:50%;
    }
    .purple{
        height:50%;
    }
    h2{
        b{
            background: -webkit-linear-gradient(135deg, #F8A185 , #F34237);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .content{
        textarea.text-input{
            width:100%;
            height:100px;
            overflow-y: auto;
        }
        .file-upload{
            width:100%;
        }
        .status{
            bottom:-30px;
        }
        .username-input{
            margin-bottom:30px;
        }
    }

}