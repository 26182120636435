.org-page {
    font-family: satoshi;
}


.org-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 200px);
    height: 100%;
    max-height: calc(100% - 80px);
    max-width: 1400px;
}


.top-header-box {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #9d8484;
    border-radius: 10px;
    color: #fff;
    box-sizing: border-box;

}

.org-info{
    width:100%;
}

.org-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-right: 20px;
    transform: translate(80px) img;

    img{
        width: 120%;
        // height: 100px;
        padding-top: 185px;
    }
    
}

.org-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    position: relative;
    padding-left: 180px;
}

.name {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.status {
    color: #ff5c5c;
    font-weight: bold;
    font-size: 12px;
    padding-left: 10px;

}

.description {
    margin: 10px 0;
    padding-left: 180px;
}

.stats {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 80px;
    font-size: 12px;

    img{
        height: 15px;
        padding-right: 10px;
    }

    .mutuals{
        display:grid;
        grid-template-columns: repeat(12, 1fr);
        position: relative;
        padding-left: 20px;
        padding-right: 0px;
    }
}

.actions {
    display: flex;
    gap: 10px;
    margin: 10px 0;
    position: relative;

    button{
        padding: 6px 30px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--red);
        font-family: satoshi;
        color: white;
    }

}

.event-info{
    display: flex;
    align-items: center;
    width: calc(100%);
    height: 100%;
    max-height: calc(100%);
    max-width: 1400px;
}

.upcoming{
    // height: 100px;
    // width: 100px;
}

// .meeting-schedule{
//     display: flex;
//     flex-direction: column;
//     width: calc(100%);
//     height: 100%;
//     max-height: calc(100%);
//     max-width: 1400px;

//     h1{
//         font: satoshi;
//         font-size: 18px;
//     }
    
//     p{
//         font: satoshi;
//         font-size: 15px;
//     }
// }

.meeting-schedule{
    display: flex;
    flex-direction: column;
    width: calc(100%);
    height: 100%;
    max-height: calc(100%);
    max-width: 1400px;

    h3{
        font-size: 18px;
    }

    .meeting-card{
        display: flex;
        flex-direction: column;
        padding: 15px;
        border-radius: 10px;
        box-shadow: var(--shadow);
        border: 1px solid var(--lightborder);
        
        .title{
            display: flex;
            flex-direction: center;
            gap: 10px;

            .logo{
                width: 50px;
                height: 50px;
                border-radius: 50%;

            }
            height: 25px;
        h4{
            display: flex;
            margin: 0px;
            height: 25px;

        }

        }
        .info{
            display: flex;
            flex-direction: column;
            padding-left: 11px;
            margin-left: 47px;
            .item{
                display: flex;
                flex-direction: center;
                gap: 5px;
            }
            // h4{
            //     display: flex;
            //     font-size: 12px;
            //     height: 0px;
            // }
            p{
                color: #666;
                font-size: 10px;
                
            }
            img{
                width: 20px;
            }
            
        }
        img{
            width: 10%;
        }
    }
}
