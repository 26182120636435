.settings{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height:100vh;
    max-height:100vh;
    position: relative;
    z-index: 1;
}

.settings-container{
    display:flex;
    max-width:1500px;
    background-color:  var(--background);
    border-radius: 21px;
    /* padding:20px 20px 60px 20px; */
    flex-direction: row;
    /* border: 1px solid var(--lightborder); */
    width:calc(100% - 100px);
    height:100%;
    max-height: min(100%, 1000px);
    overflow:hidden;
    position: relative;
    box-shadow: inset 0px 0px 0px 1px var(--lightborder),var(--shadow);
    z-index: 2;
}

.settings-left{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width:230px;
    height:100%;
    border-right: 1px solid var(--lightborder);
    position: relative;

}

.settings-right{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width:70%;
    height:100%;
    box-sizing: border-box;
    padding: 24px;
    width: calc(100% - 230px);
    overflow-y:scroll;

}

.settings-left .header{
    display: flex;
    flex-direction: row;
    /* height:63px; */
    width:100%;
    box-sizing: border-box;
    padding:9px 0;
    cursor:pointer;
    border-radius: 0 0 10px 10px;
    transition: all 0.3s;
}

.settings-left .header:hover,
.settings-left .preferences:hover{
    background-color: var(--light);
}

.settings-left .header .name{
    display: flex;
    flex-direction: column;
    /* height: 37px; */
    width: 128px;
    padding-left: 10px;
}

.settings-left .header h1{
    display: flex;
    flex-direction: column;
    font-family: 'Satoshi';
    font-size: 16px;
    color: var(--text);
    margin: 0px;
}

.settings-left .header p{
    display: flex;
    flex-direction: column;
    font-family: 'Satoshi';
    font-size: 14px;
    color: var(--darkborder);
    margin: 0 0 0px 0;
}

.settings-left .header img{
    display: flex;
    flex-direction: column;
    height:40px;
    width:40px;
    margin-left: 10px;
    border-radius: 50%;
}

.settings-left .preferences{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 18px;
    gap: 10px;
    /* height:43px; */
    width:100%;
    box-sizing: border-box;
    font-family: 'Satoshi';
    font-size: 14px;
    color: var(--text);
    transition: all 0.3s;
    border-radius: 10px;
    cursor:pointer;
}

.settings-right .header{
    display: flex;
    flex-direction: column;
    font-family: 'Satoshi';
    font-size: 24px;
    font-weight: bold;
    color: var(--text);
}

.settings-right .profile{
    display: flex;
    flex-direction: column;
    font-family: 'Satoshi';
    font-size: 14px;
    color: var(--text);
    align-items: flex-start;

}

.settings-right .profile button{
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.settings-right .profile button.active{
    background-color:var(--red);
    color: white;
}

.settings-right .name-settings{
    display: flex;
    flex-direction: row;
    font-family: 'Satoshi';
    gap: 20px;
    margin-top:10px;
}

.settings-right .name-settings .input-name{
    display: flex;
    flex-direction: column;
    font-family: 'Satoshi';
    /* gap: 200px; */
    width: 200px;
    height: 31px;
}

.settings-right .user-container{
    display: flex;
    flex-direction: row;
    font-family: 'Satoshi';
    padding-left: 20px;
    width: 100%;
    /* height: 69px; */
    margin: 0px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
}

.settings-right .user-container button{
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    font-weight:500;
    color: var(--text);
    font-size: 12px;
    padding: 5px 9px;
    height: fit-content;
    outline: none;
    border: none;
    border-radius: 6px;
    background-color: var(--light);
}

.settings-right .user-container .user{
    display: flex;
    flex-direction: column;
    font-family: 'Satoshi';
    /* width: 79px; */
    /* height: 42px; */
    .status{
        font-weight:500;
        & > div{
            display: flex;
            align-items: center;
            gap: 5px;
            iconify-icon{
                font-size:12px;
            }
            img{
                height:13px;
                width:13px;
            }
        }
    }
}

.settings-right .settings-rows-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 42px; */
    gap:10px;
    margin: 10px 0;
}

.settings-right .user-container .email{
    display: flex;
    flex-direction: column;
    font-family: 'Satoshi';
    width: 137px;
    /* height: 42px; */
}

.settings-right .user-container .password{
    display: flex;
    flex-direction: column;
    font-family: 'Satoshi';
    width: 67px;
    /* height: 42px; */
}

.settings-right .user-container .delete{
    display: flex;
    flex-direction: column;
    font-family: 'Satoshi';
    width: 100%;
    /* height: 55px; */
}


.settings-right .user-container .delete p{
    display: flex;
    flex-direction: row;
    margin: 0px;
    color: var(--darkborder);
    font-size: 12px;
}

.settings-right .user-container .delete button {
    display: flex;
    flex-direction: column;
    font-family: 'Satoshi';
    padding: 0px;
    font-size: 14px;
    height: fit-content;
    outline: none;
    border: none;
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.settings-right .user-container .delete h3{
    color: red;
}


.settings-right h2{
    font-family: 'Inter';
    font-size: 15px;
    color: var(--text);
    font-weight: 600;
    margin: 0px;
    margin-bottom: 7px;
    margin-top: 12px;
}

.settings-right h3{
    font-family: 'Inter';
    font-size: 13px;
    color: var(--text);
    font-weight: 600;
    margin: 0px;
    margin-bottom: 7px;
}

.settings-right p{
    display: flex;
    flex-direction: row;
    margin: 0px;
    color: var(--darkborder);
    font-size: 15px;
    font-weight: 600;
    font-family: 'Inter';
}

.settings-right hr{
    display: flex;
    flex-direction: column;
    font-size: 20px;
    background-color: var(--lightborder);
    height:1px;

}

.settings-right img.pfp{
    display: flex;
    flex-direction: row;
    height:62px;
    width:62px;
    margin-left: 10px;
    border-radius: 50%;
}

.settings button.developer{
    font-family: 'Satoshi';
    font-size:0.8em;
    color: white;
    background-color: var(--developerBlue);
    font-weight: normal;
    margin:20px;
    box-sizing: border-box;
    border:none;
    outline: none;
    padding: 5px 25px;
    border-radius: 10px;
    position: absolute;
    bottom:0;
    width:calc(100% - 40px);
    position: absolute;
    cursor: pointer;
    
}

.settings button.developer::after{
    transition: all 0.5s;
    content: '';
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  #45A1FC 17.8%, #8052FB 100.2% );
    filter: blur(20px);
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
}


@media (max-width:700px) {
    .settings-left{
        display:block;
        width:100%;
        border-right:none;
    }

    .settings-left .header{
        padding: 20px;
        padding-bottom: 10px;
        gap: 10px;
        position: relative;
    }

    .settings-left .header img{
        height:50px;
        width:50px;
        padding: 0px;
        
    }

    .settings-left .header .right-arrow{
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        top:0;
        right:20px;
        background: none;
        border: none;
    }

    .settings-left .header .right-arrow img{
        height: 17px;
        width: 17px;
    }

    .settings-left .header h1{
        font-size: 18px;
        
    }

    .settings-left .header p{
        font-size: 17px;
        
    }

    .settings-left .preferences{
        font-size: 17px;
        padding: 0px 24px;
        position: relative;
    }

    .settings-left .preferences img{
        width: 25px;
    }

    .settings-left .preferences .right-arrow img{
        height: 17px;
        width: 17px;
    }
    .settings-left .preferences .right-arrow{
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        top:0;
        right:20px;
        background: none;
        border: none;
    }

    .settings-container{
        width:100%;
        box-sizing: border-box;
        height:100%;
        max-height:100%;
        flex-direction: column;
        overflow:hidden;

    }
    .settings-right{
        width: 100%;
        transition: all 0.5s ease;
        transform:translateX(100%);
        position:absolute;
        background-color: white;
        border: 1px solid var(--lightborder);
        /* box-sizing: border-box; */
        border-radius: 20px;
    }

    .settings-right.active{
        overflow-x: hidden;
        transform:translateX(0px);
    }

    /* .settings-right.not-active{
        transform:translateX(-100%);
    } */

    .settings-right .user-container{
        padding: 10px;
    }

    .settings-right .header{
        position: relative;
        padding-left: 20px;

    }

    .settings-right .header img{
        height: 17px;
        width: 17px;
    }

    .settings-right .header .back-arrow{
        display:flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        top:0px;
        left:-15px;
        background: none;
        border: none;
        height: calc(100% - 10px);
        padding: 0;
        margin-bottom: 10px;
        box-sizing: border-box;
    }

}