li.result{
    border-radius: 0;
    padding:0;
    background-color: var(--background);
    width:93%;
    border: 1px solid var(--lightborder);
    border-radius: 10px;
    margin-bottom: 15px;;
    display:flex;
    flex-direction: column;
    gap:6px;
    pointer-events: all;
    /* margin:5px; */
    position: relative;
}

.restricted::before{
    content:"restricted access";
    position: absolute;
    top:10px;
    padding:2px 10px;
    border-radius: 5px;
    background-color: var(--red);
    color:white;
    z-index: 10;
    font-size: 13px;
    align-self: center;
}


.result .image{
    border-radius: 9px 9px 0 0 ;
    width:100%;
    height:150px;
    min-width: 100px;
    object-fit: cover;
    overflow:hidden;
    position: relative;
    display:flex;
    align-items:center;
}

.result .saved-img{
    position: absolute;
    top:-8px;
    right:10px;
    padding: 5px;
    border-radius: 5px;
    color:white;
    font-size: 13px;
    z-index: 7;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.result .image img{
    width:100%;
    height:auto;
    position: absolute;
    z-index: 1;
    pointer-events: none;
}

.result:hover{
    filter: brightness(90%);
    cursor:pointer;
}

.result h2{
    font-family:'Inter';
    font-weight:600;
    font-size:17px;
    margin: 0;
    hyphens:auto;
    -webkit-hyphens: auto;
    color:var(--text);
}

.result-info{
    padding:0 15px 10px 15px;
    display:flex;
    flex-direction: column;
}

.result .rating{
    display:flex;
    flex-direction: row;
    gap:5px;
    align-items: center;

}

.result .rating p{
    margin:0;
    font-size:14px;
    font-family:'Inter';
    font-weight:700;
}

.result .rating img{
    width:15px;
    height:15px;

}

.result-info .info-row{
    display:flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
    margin: 7px 0;
}

.result-info-container{
    display:flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
}

@media(max-width:500px){
    .result h2{
        font-family:'Inter';
        font-weight:600;
        font-size:16px;
        margin: 0;
        hyphens:auto;
        -webkit-hyphens: auto;
    }
}