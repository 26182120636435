.create-event{
    height:100vh;
    .content-container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:100%;
        height:calc(100% - 80px);
        max-height:calc(100% - 80px);
        padding: 20px 20px 40px 20px;
        box-sizing: border-box;
        flex-grow:1;
        .content{
            display:flex;
            max-width:1500px;
            background-color: var(--background);
            border-radius: 21px;
            flex-direction: row;
            width:calc(100% - 100px);
            height:100%;
            max-height: min(100%, 1000px);
            overflow:hidden;
            position: relative;
            // box-shadow: inset 0px 0px 0px 1px var(--lightborder),var(--shadow);
            border:1px solid var(--lighterborder);
            box-shadow: var(--shadow);
            flex-grow: 1;
            .create-steps{
                height: 100%;
                width:180px;
                min-width: 180px;
                background-color: var(--lighter);
                border-radius: 14px 0 0 14px;
                border-right: 1px solid var(--lighterborder);
                h1{
                    font-size:23px;
                }
                .create-header{
                    box-sizing: border-box;
                    padding: 16px 16px;
                    .alias{
                        display:flex;
                        gap: 10px;  
                        p{
                            font-weight:500;
                        }
                        .choice-container{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            flex-grow:1;
                            position: relative;
                            .choose{
                                cursor: pointer;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                flex-grow:1;
                                position:relative;
                                background-color: var(--background);
                                padding:3px 10px;
                                box-sizing: border-box;
                                border-radius: 5px;
                                border:1px solid var(--lighterborder);
                                iconify-icon{
                                    position:absolute;
                                    right:5px;
                                    cursor:pointer;
                                }
                                .choice{
                                    display: flex;
                                    gap: 5px;
                                    img{
                                        height:20px;
                                    }
                                    p{
                                        -webkit-user-select: none;
                                        user-select: none;
                                        display: -webkit-box;              /* Establishes a flexbox-like context in WebKit */
                                        -webkit-box-orient: vertical;      /* Ensures that the box is laid out vertically */
                                        -webkit-line-clamp: 1;     
                                        overflow: hidden;
                                    }
                                }
                            }
                            .dropdown{
                                animation: fadeIn 0.3s forwards ease-in;
                                position:absolute;
                                background-color: var(--background);
                                top:120%;
                                z-index: 99;
                                width:200%;
                                left:0%;
                                border: 1px solid var(--lighterborder);
                                border-radius: 8px;
                                box-shadow: var(--shadow);
                                overflow:hidden;
                                .drop-option{
                                    display: -webkit-box;              /* Establishes a flexbox-like context in WebKit */
                                    -webkit-box-orient: vertical;      /* Ensures that the box is laid out vertically */
                                    -webkit-line-clamp: 1;             /* Number of lines you want to display */
                                    overflow: hidden;                  /* Hides the overflowing text */
                                    text-overflow: ellipsis;  
                                    display: flex;
                                    gap: 5px;
                                    padding:5px 10px;
                                    cursor: pointer;
                                    transition: all 0.3s;
                                    background-color: var(--background);
                                    &:hover{
                                        filter:brightness(0.95);
                                    }
                                    &:not(:first-child){
                                        border-top:1px solid var(--lighterborder);
                                    }
                                    img{
                                        -webkit-user-select: none;
                                        user-select: none;
                                        height:20px;
                                    }
                                    p{
                                        -webkit-user-select: none;
                                        user-select: none;

                                    }
                                }   
                            }
                        }

                    }
                }
                .steps{
                    display:flex;
                    flex-direction: column;
                    
                    .step{
                        cursor: pointer;
                        display:flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 8px 32px 8px 16px;
                        box-sizing: border-box;
                        position: relative;
                        gap:8px;
                        overflow: hidden;
                        img{
                            height:20px;
                            width:20px;
                        }
                        p{
                            font-size:15px;
                            margin: 0;
                            font-family: 'Inter';
                            font-weight: 600;
                        }
                        &::after{
                            content: "";
                            position: absolute;
                            width: 5px;
                            height: 60%;
                            border-radius:0  3px 3px 0;
                            left: -5px;
                            transition: all 0.3s;;
                        }
                        &.selected{
                            &::after{
                                content: "";
                                position: absolute;
                                width: 5px;
                                height: 60%;
                                border-radius:0  3px 3px 0;
                                background-color: var(--red);
                                left: 0;
                            }
                        }
                    }
                }
            }
            .create-workspace{
                flex-grow:1;
                overflow:auto;
                .next-button{
                    position: absolute;
                    bottom:20px;
                    right:20px; 
                    font-family: "Satoshi";
                    font-size: 15px;
                    outline: none;
                    border-radius: 12px;
                    background-color: var(--light);
                    border: none;
                    padding: 5px 20px;
                    margin: 10px;
                    color: white;
                    width: 200px;
                    pointer-events: none;
                    transition: all 0.5s;
                    margin:0;
                    cursor: pointer;
                    &.active{
                        background-color: var(--red);
                        color: white;
                        pointer-events: all;
                    }
                    &:hover{
                        background-color: var(--background);
                        color: var(--red);
                        box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
                        cursor:pointer;
                    }
                }
            }
        }
    }
}