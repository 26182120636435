.DayColumn{
    display:grid;
    grid-template-rows: repeat(30, 1fr);
    position: relative;
    height:530px;   
    width:120px;
}

.cross{
    position:absolute;
    top:10px;
    right:10px;
    background-image: url('../../../assets/x.svg');
    background-repeat: none;
    background-size:cover;
    height:10px;
    width:10px;
}

.event{
    position: relative;
    border-radius: 5px;
    padding: 5px;
    margin:6px;
    transform: translateY(1px);
    background-color:#B1E6B0;
    /* border: 3px solid darkgreen; */
    box-sizing: border-box;
    /* transform:translateY(1px) */
    opacity:0.8;
    font-family:'Satoshi';
    font-weight: bold;
    color: #414141;
    transition: all 0.3s ease;
    text-align: left;
    font-size:12px;
}

.dark-mode .event{
    opacity: 1;
    color: #414141;
}

.remove-event{
    position:absolute;
    color: white;
    background-color:var(--red);
    border-radius: 3px;
    outline:none;
    border:none;
    padding: 3px 10px;
    width: calc(100% - 14px);
    bottom:7px;
    left:7px;
    font-family: 'Satoshi';
    opacity:0;
    transition:all 0.5s;
}

.event:hover .remove-event{
    opacity:0.8;
}

.remove-event:hover{
    transform:scale(1.05);
    cursor:pointer;
}

.search{
    border: 2px solid #A2ABAB;
    box-sizing: border-box;
    background-color: rgba(229,229,229,0.8);

}

.event:hover{
    transform: scale(1.01);
    cursor: pointer;
}

.grid{
    display:grid;
    grid-template-rows: repeat(60, 1fr);
    position: relative;
    height:100%;   
    width:100%;
    position: absolute;
}

.selection-overlay{
    display:grid;
    grid-template-rows: repeat(30, 1fr);
    position: relative;
    height:calc(100% - 10px);   
    width:calc(100% - 10px);
    position: absolute;
    background-color: rgba(229,229,229,0.5);
    border-radius: 5px;
    border:2px solid #A2ABAB;
    pointer-events: none;
    opacity:0.8;
    margin:6px;
    box-sizing: border-box;
}

.grid-item{
    position: relative;
    box-sizing: border-box;
    border-top: 2px solid var(--lighterborder);
    margin: 0 2px;
    border-bottom: 2px solid var(--background);
}

.noborder{
    border:none;
}

.redborder{
    border-top: 3px solid var(--red);
    border-bottom: 1px solid white;
}

.grid-item:hover:not(.no-interaction){
    background-color: #F5F5F5;
    cursor: pointer;
}

.time{
    opacity: 0.8;
    font-size:10px;
    font-family: 'Satoshi';
    font-weight: normal;
    margin: 0 0 1px 0 ;
    color: #414141;
}

.search .time{
    font-size: 11px;
    margin: 0 0 1px 0;
}

.class-name{
    font-size:13px;
    margin:0;
    color: #414141;

}

@media(max-width: 1150px){
    .DayColumn{
        width: 100px;
        height:450px
    }

    .event{
        font-size: 10px;
    }

}

@media(max-width: 600px){
    .DayColumn{
        width: 100%;
        height:450px
    }

    .event{
        font-size: 10px;
    }

}

@media(max-width: 500px){
    .event{
        font-size: 10px;
        margin:4px;
    }


}

@media(max-width: 400px){

    .event {
        margin:3px 3px 4px 3px;
    }
}

@media(max-height: 735px){

   .DayColumn{
        height:400px;
   }
}

@media(max-height: 645px){

    .DayColumn{
         height:350px;
    }
 }

 @media(max-height: 585px){

    .DayColumn{
         height:300px;
    }
 }