.mini-footer{
    display:flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    padding:5px;
    height:35px;
    box-sizing: border-box;
}

.mini-footer p{
    color:var(--darkborder);
    font-size:15px;
    margin:0;
}

.mini-footer .github{
    width:20px;
    height:20px;
}
