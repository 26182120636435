.settings-right .profile input:not(.slider){
    border: none;
    border-radius: 6px;
    background-color:var(--light);
    padding: 5px;
    font-family: 'Inter';
    font-size: 13px;
    color: var(--text);
    font-weight: 600;
}

.settings-right{
    .profile{
        .name-settings{
            .picture-container{
                position:relative;
                .add-picture{
                    position: absolute;
                    bottom:-2px;
                    right:-2px;
                    color:white;
                    padding:2px;
                    background-color: rgba(var(--text-rgba), 0.8);
                    border-radius: 3px;
                    box-sizing: border-box;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    cursor:pointer; 
                    iconify-icon{
                        color:white;
                        font-size: 20px;

                    }
                }
            }
        }
        .pfp-upload{
            height:0;
            pointer-events: none;
            overflow: hidden;
            opacity: 0;
            transition: all 0.5s;
            padding:5px;
            box-sizing: border-box;
            width:60%;
            margin-top:30px;
            img:not(.clear){
                width:50px;
                height:50px;
                border-radius: 50%;
            }
            &.active{
                opacity: 1;
                height:150px;
                pointer-events: all;
            }
        }
    }
}