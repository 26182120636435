.loader{
    height:80%;
    width:80%;
    top:0;
    left:0;
    display:flex;
    align-items: center;
    justify-content: center;
    /* transform: translate(-50%, -50%); */
    border-radius: 10px;
    opacity:1;
    transition: 0.1s all;
    pointer-events: none;
}

.loader img{
    height:50px;
    width:50px;
    animation: rotate 0.7s ease-in-out infinite reverse;
}

@keyframes rotate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-360deg);
    }
}