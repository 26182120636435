.approval{
    display: flex;
    border: 1px solid var(--lighterborder);
    background-color: var(--background);
    border-radius: 10px;
    padding:10px 15px;
    width:300px;
    gap: 10px;
    box-shadow: var(--shadow);
    h3{
        color: var(--text);
        margin: 0;
    }
    .approval-status{
        padding: 2px 10px;
        width:fit-content;
        border-radius: 7px;
        p{
            font-family: 'Satoshi';
            color: var(--lighter-text);
            font-size:13px;
        }
        &.low{
            background-color: var(--green-bg);
            p{
                color:var(--green-main);
            }
        }
    }
    .info.row{
        .col{
            width:50%;
        }
        .criteria{
            display: flex;
            flex-direction: column;
            border: 1px solid var(--lighterborder);
            border-radius: 8px;
            background-color: var(--lightest);
            overflow: hidden;
            gap: 0;
            .header{
                padding: 4px 8px;
                border-bottom:1px solid var(--lighterborder);
                background-color: var(--background);
                p{
                    font-size:12px;
                }
            }
            .content{
                padding:5px 8px;
                .criteria-item{
                    display: flex;
                    gap: 3px;
                    background-color: var(--background);
                    border: 1px solid var(--lighterborder);
                    padding: 2px 5px;
                    border-radius: 5px;
                    align-items: center;
                    iconify-icon{
                        font-size:12px;
                        color: var(--text)
                    }
                    p{
                        font-size:12px;
                        font-weight:500;
                        font-family: 'Inter';
                        color: var(--text);
                    }
                }
            }
        }
    }
    .actions{
        gap: 5px;
        justify-content: flex-end;
        margin: 0;
    }
    .button{
        height:fit-content;
        width:fit-content;
        padding:5px 15px;
        font-size: 14px;
        color:var(--text);
        background-color: var(--oie-background);
        pointer-events: all;
        margin: 0;
        width:100%;
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        iconify-icon{
            transition: transform 0.2s;
        }
        p{
            font-size:13px;
            font-weight: 700;
            margin-right:10px;
            font-family: 'Inter';
            color: var(--text);
        }
        &:hover iconify-icon{
            transform: scale(1.2);
        }
    }
}