.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;

  }

  .popup-content-overlay{
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    position: absolute;
    max-width:500px;
    box-sizing: border-box;
    display:flex;
    flex-direction: column;
    height:fit-content;
    /* opacity: 0; */   
    margin: 0 20px;
    overflow:hidden;

    &.wide-content{
      max-width: 1000px;
      width:95%;
      

    }
  }

  
  .popup-overlay .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    position: relative;
    max-width:500px;
    box-sizing: border-box;
    display:flex;
    flex-direction: column;
    height:fit-content;
    /* opacity: 0; */   
    margin: 0 20px;
    overflow:hidden;

    &.wide-content{
      max-width: 1000px;
      width:95%;
      .close-popup{
        background-color: var(--background);
        padding:7px;
        top:5px;
        right:10px;
        border-radius:50%;
        animation: popout 0.3s forwards cubic-bezier(0.075, 0.82, 0.165, 1);
        animation-delay:0.1s;
        transform:translateX(-50px);
        opacity:0;
      }
      @keyframes popout {
        from {
          transform:translateX(-50px);
          opacity: 0;
        }
        to {
            transform:translateX(0);
            opacity: 1;
        }
        
      }

    }

    &.oie{
        max-height:600px;
    }
  }

  .popup-overlay .popup-content.no-styling {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
  }

  
  .popup-overlay .popup-content.no-padding {
    padding: 0;
  }
  .popup-content .close-popup{
    position: absolute;
    width:15px;
    top:15px;
    font-size:18px;
    color:var(--lighter-text);
    right:15px;
    z-index: 100;
    cursor: pointer;
  }

  .close-popup.popout{
    width:15px;
    height:15px;
    background-color: var(--background);
    padding:7px;
    border-radius:50%;
    position: absolute;
  }
  
  /* Entry and Exit Animations */
  .fade-in {
    animation: fadeIn 0.3s forwards;
  }
  
  .fade-out {
    animation: fadeOut 0.3s forwards;
  }
  
  .slide-in {
    animation: slideIn 0.3s forwards;
  }
  
  .slide-out {
    animation: slideOut 0.3s forwards;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
  