.sort-popup .switch{
    font-size: 15px;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 39px;
    margin-top:10px;
}

.sort-popup .slider{
    position: absolute;
    cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    background-color: var(--lightborder);
    color: var(--darkborder);
    font-weight: 600;
    border-radius: 7px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: .4s;
    -webkit-tap-highlight-color: transparent;

}

.sort-popup .slider span{
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* Internet Explorer/Edge */
    user-select: none;         /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */

}

.sort-popup .slider:before {
    position: absolute;
    content: "include";
    height:calc(100% - 6px);
    width: calc(50% - 3px);
    left: 3px;
    border-radius: 6px;
    background-color: white;
    color: var(--text);
    display: grid;
    align-content: center;
    justify-content: center;
    transition: .4s;

}

/* .sort-popup .slider:after {
  content: "";
  position: absolute;
  top: -7px;
  left: -7px;
  right: -7px;
  bottom: -7px;
  border-radius: 1.71em;
  z-index: -1;
} */


.sort-popup .switch input:checked + .slider:before {
  content: "exclude";
  transform: translateX(100%);
  color: var(--text);
}

.sort-popup .switch input {
  display: none;
  
}

.tags-container{
    overflow-x:hidden;
    overflow:hidden;
    height:180px;
    margin-top:10px;
}

.tags-content{
    display:flex;
    width:210%;
    transition:all 0.4s;
    gap:5%;
    height:180px;
}

.next{
    transform:translateX(-52.5%);
}

.include,
.exclude{
    width:50%;
    display:flex;
    flex-direction: column;
    gap:5px;
    max-height: 180px;
    min-height: 180px;
    overflow-y:scroll;
}

.include::-webkit-scrollbar,
.exclude::-webkit-scrollbar {
  width: 10px;
  margin:2px; /* or height for horizontal scrollbars */
}

.include::-webkit-scrollbar-thumb,
.exclude::-webkit-scrollbar-thumb {
  width:8px;
  border:2px solid white;
}

.clear{
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* Internet Explorer/Edge */
    user-select: none;         /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */
    transition: all 0.2s;
}

.clear:active{
    transform: scale(1.1);
}


