.DragList{
    width:100%;
}

.DragList nav {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
}

.DragList ul,
.DragList li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Inter';
}

.DragList ul {
    position: relative;
    width: 100%;
}

.DragList li {
    border-radius: 10px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 18px;
    /* position: relative; */
    background-color: var(--background);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    flex-shrink: 0;
    cursor: grab;
    border: 1px solid var(--lightborder);
    box-shadow: var(--shadow);
    color: var(--text);
}

.DragList li h3{
    font-size: 14px;
    font-weight: 700;
    font-family: 'Inter';
    margin: 0;

}

.preference-list{
    width:100%;
}

.preference-list p.most{
    color:var(--red);
    font-family: 'Satoshi';
    font-weight: bold;
    font-size: 14px;
}

.preference-list p.least{
    color:var(--blue);
    font-family: 'Satoshi';
    font-size: 14px;
    font-weight: bold;
}

.DragList li h4{
    font-size: 13px;
    font-weight: 500;
    font-family: 'Inter';
    margin: 0;
    margin-top: 5px;
    text-align: left;
    color: var(--border);
}

.DragList li svg {
    width: 18px;
    height: 18px;
    cursor: grab;
}

.DragList .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background: #fff;
}

@media (max-width:500px) {
    .DragList ul,
    .DragList li {
        list-style: none;
        padding: 0;
        margin: 0;
        font-weight: 500;
        font-size: 15px;
        font-weight: 600;
        font-family: 'Inter';
    }


    .DragList li h3{
        font-size: 13px;
        font-weight: 700;
        font-family: 'Inter';
        margin: 0;
    }

    .DragList li{
        padding: 12px 15px;
        margin-bottom:5px
    }
}