.create-component{
    display:flex;
    flex-direction: column;
    padding: 25px 20px 20px 20px;
    box-sizing: border-box;
    flex-grow: 1;
    height:100%;
    display:none;
    &.visible{
        display:flex;
    }
    h1{
        font-size: 21px;
    }

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--dark);
        width:6px;
        border: 3px solid var(--background);
    }

}