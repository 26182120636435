.card{
    border-radius:10px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
    gap:20px;
    overflow: show; 
}

.card .left-profile{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-height:100%;
    width: 33%;
    max-width: 310px;
    gap:20px;
    min-width:300px;

}

.card .header{
    width: 100%;
    min-height:-moz-fit-content;
    min-height:fit-content;
    border-radius: 21px;
    border: 1px solid var(--lightborder);
    position: relative;
    max-height:300px;
    box-sizing: border-box;
}




.card .saved{
    background-color: var(--background);
    border-radius: 21px;
    border: 1px solid var(--lightborder);
    /* height: 52%; */
    width: 100%;
    box-shadow: var(--shadow);
    overflow: scroll;
    flex-grow:1;
    box-sizing: border-box;
}

.card .study-history{
    flex-grow:1;
    background-color: var(--background);
    border-radius: 21px;
    border: 1px solid var(--lightborder);
    height: 100%;
    box-shadow: var(--shadow);
    box-sizing: border-box;
}

.card .box-header{
    display:flex;
    flex-direction: column;
    align-items: center;
    /* height: 100%; */
}

.card .box-header img{
    height:60%
}

.card .box-header .title{
    display:flex;
    border-bottom: 1px solid var(--lightborder);
    justify-content: left;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    gap: 5px;
    width: 100%;
    height: 35px;
}

.card .box-header p{
    font-family: 'Satoshi';
    color: var(--text);
    font-weight: bold;
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}


@media (max-width:500px) {
    .card{
        border-radius:10px;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        height: 100%;
        width: 100%;
    }
    .card .header{
        height: 267px;
    }

    .card .saved{
        height: 317px;
    }

    .card .left-profile{
        height: auto;
        width: 100%;
        justify-content: flex-start;
        gap: 10px;  
        max-width: none;
    }

    .card .study-history{
        width: 100%;
        background-color: white;
        border-radius: 21px;
        height: 33%;
        box-shadow: var(--shadow);
    }
}