.profile-creation{
    box-sizing: border-box;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: white;
    /* height: 400px;
    width: 630px; */
    border-radius: 8px; 
    width:100%;
    height:400px;
}

.left-benefits{
    position: relative;
    width: 63%;
    height: 100%;
    background-color: var(--salmon);
    border-radius: 8px 0px 0px 8px;
}

.right-benefits{
    margin: 5% 3%;
    width: 33%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}


.pop-up.in {
    opacity: 1;
}

.pop-up.out {
    opacity: 0;
    pointer-events: none;
}

.pop-up .loader{
    height: 15%;
    margin-top: 6%;
    margin-bottom: 3%;
}

.profile-creation p{
    color: var(--black);
    font-size: 13px;
    text-align: left;
    margin-bottom: 55%;
}

.profile-creation .button{
    font-family: 'Satoshi';
    font-size: 15px;
    outline: none;
    border-radius: 6px;
    background-color: var(--red);
    border: none;
    padding: 0px 0px;
    margin-top: 10px;
    color: white;
    height: 27px;
    width: calc(100%);
    pointer-events: all;
}


.close-button {
    position: absolute;
    top: 13px;
    right: 13px;
    background: none;
    border: none;
    cursor: pointer;
}

.close-button img{
    height: 15px;
}


@media (max-width:500px) {
    .profile-creation{
        justify-content: center;
    }

    .profile-creation h1{
        text-align: center;
    }
    .left-benefits{
        width: 0%;
    }
    .right-benefits{
        width: 60%;
    }
}