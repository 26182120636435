.Header{
    width:100%;
    display:flex;
    justify-content: center;
    position: relative;
    max-width: 100vw;
    flex-wrap: nowrap;

    .header-content{
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding:15px;
        box-sizing: border-box;
        height:80px;
        width: calc(100% - 100px);
        max-width: 1500px;
        position: relative;
        z-index: 20;

        .school{
            display:flex;
            align-items: center;
            gap:10px;
            cursor:pointer;
            hr{
                width: 2px;
                height: 30px;
                background-color: var(--text);
                margin:0;
                border-radius: 4px;
            }
            h2{
                margin: 0;
                font-size:20px;
                font-family: 'Satoshi';
                color: var(--red);
                font-weight: 500;
                
            }
        }
    }
    .checked-in-banner{
        position: absolute;
        bottom:-10px;
        left:0;
        width:100%;
        height: 15px;
        background-color: var(--green);
        display:flex;
        align-items: center;
        justify-content: center;
    
        p{
            font-family: 'Inter';
            font-size: 12px;
            color: white;
            margin:0;
        }
    }

}

.logo{
    height:47px;
    z-index: 2;
}

.header-right{
    height:50px;
    /* width:50px; */
    z-index: 2;
}

.Header a{
    text-decoration: none;
    color: var(--text);
    display:flex;
    align-items: center;
}

.Header button{
    font-family: 'Satoshi';
    font-size: 15px;
    outline:none;
    border-radius: 12px;
    background-color: var(--red);
    border:none;
    padding:5px 20px;
    margin:10px;
    color: white;
    width:calc(100% - 20px);
    pointer-events: all;
    transition: all 0.5s;
}

.Header button:hover{
    background-color: var(--background);
    color: var(--red);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    cursor:pointer;
}

.Header h2{
    font-family: 'Inter';
    font-weight:600;
    font-size: 15px;
    margin:0;
    color: var(--text);
    cursor:pointer;
}

.Header nav{
    display:flex;
    gap:40px;
    position: relative;

}

.Header .underline{
    position: absolute;
    width:10px;
    height: 3px;
    background-color: var(--red);
    bottom:0;
    left:0;
    transition: all 0.3s ease;
    transform:translateY(10px);
    border-radius: 3px;
    transition: width 0.3s ease;
}

.Header .nav-container{
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    display:flex;
    align-items: center;
    justify-content: center;
    z-index:1;
}

.Header .nav-link{
    position: relative;
}

.Header .nav-link::after{
    left:-5px;
    bottom: -10px;
    width: 0;
    border-radius: 3px;
    height:3px;
    background-color: var(--red);
    content: '';
    position: absolute;
    transition: all 0.5s;
}

.Header .nav-link.active::after{
    width: calc(100% + 10px);
}

@media(max-width: 500px){
    .Header .header-content{
        max-width: 100%;
        width:100%;
    }
}