.event-component{
    display:flex;
    width:100%;
    gap:10px;
    padding:10px;
    box-sizing: border-box;
    flex-direction: column;
    box-shadow:var(--shadow);
    background-color: var(--background);
    border-radius: 10px;
    border: 1px solid var(--lighterborder);
    cursor:pointer;
    
    iconify-icon{
        font-size: 16px;
        color: var(--text);
    }
    img{
        width:calc(100%);
        border-radius: 5px;
        box-shadow:0px 7px 29px 0px rgba(150, 150, 157, 0.1);

    }
    .info{
        h1{
            font-size: 18px;
            font-weight: 600;
            margin: 0;
            color: var(--text);
        }
        p{
            font-size: 14px;
            font-weight: 400;
            margin: 0;
            color: var(--text);
            font-family: 'Inter';
        }
    }
}