.new-approval{
    width:100%;
    height:500px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--lighterborder);
    background-color: var(--background);
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    .header{
        display: flex;
        width:100%;
        padding: 10px 15px;
        background-color: var(--background);
        display: flex;
        flex-direction: column;

        h2{
            margin: 0;
            font-family: 'Satoshi';
            font-size:22px;
        }
        p{
            font-family: 'Satoshi';
            font-size: 15px;
            color:var(--lighter-text);
        }

    }
    .flag{
        padding:10px;
        font-size:14px;
        width: calc(100% - 20px);
        margin:auto;
        margin-top:5px;
        margin-bottom:5px;
    }
    .content{
        padding: 5px 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .field{
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            gap: 5px;
            label{
                font-family: 'Inter';
                font-weight:700;
                font-size:14px;
                color: var(--text);
                margin-left:5px;
            }
            input{
                &.short{
                    width:200px;
                }
            }
        }
    }
}