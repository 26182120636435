.oie-event-component{
    display:flex;
    flex-direction: column;
    width:250px;
    min-width:250px;
    gap:10px;
    padding:10px;
    box-sizing: border-box;
    box-shadow:var(--shadow);
    background-color: var(--background);
    border-radius: 10px;
    border: 1px solid var(--lightborder);
    justify-content: space-between;
    animation: fadeIn 0.5s forwards;
    opacity: 0;

    img{
        width:calc(100%);
        border-radius: 5px;
        box-shadow:0px 7px 29px 0px rgba(150, 150, 157, 0.1);

    }
    .info{
        display:flex;
        flex-direction: column;
        gap:5px;
        .oie-status{
            padding:1px 12px;
            border-radius: 5px;
            width:fit-content;
            background-color: var(--green);
            &.approved{
                background-color: var(--green);
            }
            &.not-applicable{
                background-color: var(--dark-blue);
            }
            p{
                font-size:11px;
                color:var(--background);
                margin:0;
                font-family: 'Inter';
                font-weight:500;
            }
        }

        h2{
            font-size: 15px;
            font-weight: 600;
            margin: 0;
            color: var(--text);
            font-weight: 700;
            font-family: 'Satoshi';
        }
        
        .level{
            font-size:10px;
            font-weight: 700;
            color: var(--background);
            background-color: var(--green);
            padding:1px 5px;
            border-radius: 5px;
            &.faculty{
                background-color: var(--blue);
            }
            &.organization{
                background-color: var(--red);
            }
            &.developer{
                background-color: var(--developerBlue);
            }
        }
        

        .row{
            display:flex;
            gap:5px;
            align-items: center;
            font-size:18px;
            color: var(--text);
            .user-name{
                font-weight: 700;
                font-size:13px;
                white-space: nowrap;  
                overflow: hidden;    
                text-overflow: ellipsis;
            }
            img{
                width: 20px;
                height: 20px;
            }
            p{
                font-size: 12px;
                font-weight: 500;
                margin: 0;
                color: var(--text);
                font-family: 'Inter';
            }
        }
    }
    .button{
        height:fit-content;
        width:fit-content;
        padding:5px 15px;
        font-size: 14px;
        color:var(--text);
        background-color: var(--oie-background);
        pointer-events: all;
        margin: 0;
        iconify-icon{
            transition: transform 0.2s;
        }
        p{
            font-size:13px;
            font-weight: 700;
            margin-right:10px;
        }
        &:hover iconify-icon{
            transform: scale(1.2);
        }
    }

}