.events-column{
    width:calc((100% - 40px) / 3);
    overflow:visible;
    transform:translateY(50%);
    transition:1s;
    display:flex;
    flex-direction:column;
    align-items:center;
    gap:20px;
    opacity: 0;
}

.events-column.active{
    transform:translateY(0);
    opacity: 1;
}