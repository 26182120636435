.recommended-container{
    width:100%;
    transition: all 0.5s;
    /* display:flex;
    justify-content: center;
    align-items: center; */
}

.recommended-container.hide{
    height:0;
    opacity:0;
    overflow:hidden;

    pointer-events: none;
}

.recommended{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 13px;
    outline: none;
    border-radius: 15px;
    background-color: var(--offwhite);
    border: none;
    margin:  20px 20px 0 20px;

    width: calc(100% - 40px);

    /* height: 40%; */
    z-index: 20;
}


.recommended p{
    margin-top: 10px;
    font-family: 'Satoshi';
    font-size: 18px;
}

@media (max-width:500px) {
    .recommended{
        margin:  20px 20px 0 20px;

        width: calc(100% - 40px);
        
    }
}