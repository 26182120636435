.mobile-calendar{
    max-width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    border-radius:21px;
    border: 1px solid var(--lighterborder);
    background-color: white;
    position: absolute;
    /* transition: all 0.5s; */
    z-index: 20;
    box-sizing: border-box;
    /* transform: translateY(1000px); */    
}

.mobile-calendar.hidden{
    transform: translateY(1000px) !important;
}

.mobile-calendar.active{
    transform: translateY(0px);
}

.swiper-slide-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0 0 0;
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
    margin-bottom:20px;
}

.day-name{
    /* width: 20%; */
    color: #B0AFAF;
    font-family: 'Satoshi';
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    margin:0;
    margin-top:10px;
    margin-bottom: 15px;
}

.current-day-mobile{
    background-color: var(--red);
    color: white !important;
    border-radius: 10px;
    padding: 5px 15px;
    box-sizing:border-box;
    margin-top:5px;
    margin-bottom:10px
}

.swiper-container{
    width:60%;

}

.mobile-time{
    position: absolute;
    margin-top:65px;
    transform:translateX(-30px)
}

.swiper-pagination-bullet-active {
    background-color: var(--red) !important; /* Light gray color */
    opacity: 1;
  }

.hide-mobile-calendar{
    position: absolute;
    top:10px;
    left:0;
    z-index: 10;
    transition: all 0.5s;
    height:20px;
    width:100px;
    left:calc(50% - 50px);
}

.grab{
    position: absolute;
    top:7px;
    width:50px;
    height:5px;
    background-color: var(--lightborder);
    border-radius: 5px;
}