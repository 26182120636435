.oie-dash .dash{
    height: 100vh;
    width:100%;
    padding:20px;
    box-sizing: border-box;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap:10px;
    transition: padding 1s ease-in-out;
    max-height:100vh;

    &.maximized{
        padding:20px;
    }

    header.header{
        height:110px;
        position: relative;
        border-radius: 13px;
        overflow: hidden;
        display:flex;
        h1{
            z-index: 2;
            padding-left:13%;
            font-size: 30px;
        }
        img{
            position: absolute;
            top:0;
            left:0;
            height:100%;
        }
    }
    .needs-approval{
        display: flex;
        flex-direction: column;
        h1{
            font-size: 20px;
            margin: 0;
        }
        .approval-header{
            display: flex;
            gap: 15px;
            align-items: center;
            box-sizing: border-box;
            padding:0 10px;
            button{
                font-family: 'Inter';
                font-size: 13px;
                outline:none;
                border-radius: 8px;
                border:none;
                font-weight: 600;
                padding: 3px 18px;
                cursor: pointer;
                height:fit-content;
            }
        }
        .content{
            padding: 10px 0 10px 0;
            display:flex;
            gap:10px;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                height:6px;
            }
        }
    }
    .week-container{
        display: flex;
        gap: 10px;
        flex-direction: column;
        flex-grow:1;
        // max-height:60%;
        // overflow-y:hidden;
        .header .time-period{
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 10px;
            align-items: center;
            h1{
                margin: 0;
                font-size:20px
            }
        }
    }
}