.friends-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding:0 15px;
}

.friends{
    overflow:hidden;
    .dark-overlay{
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 18;
        display: block;
        top:0;
        left:0;
        pointer-events: none;
        transition: all 0.5s ease;
        opacity: 0;
    }
    .dark-overlay.active{
        opacity: 1;
        pointer-events: all;
    }
}

.friends-container .profile-picture img{
    border-radius: 50%;
    background-color: white;
}

.friends-content{
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    height: 100%;
    max-height: calc(100% - 80px);
    max-width: 600px;
    gap:30px;
    padding-bottom:20px;
    box-sizing: border-box;
}

.friends .profile-picture{
    height:50px;
}

.friends .user{
    /* border: 1px solid var(--lightborder); */
    width: calc(100% - 20px);
    padding: 10px;
    border-radius: 15px;
    box-shadow: var(--shadow);
    /* box-sizing: border-box  ; */
    display:flex;
    gap:10px;
    position: relative;
    overflow:hidden;
    height:65px;
}

.friends .user .gradient{
    height:100%;
    position: absolute;
    right:0;
    top:0;
}

/* .friends .user .profile-picture{
    width:40px;
    height:40px;
}

.friends .user .profile-picture img{
    width:100%;
    height:100%;
    border-radius: 50%;
} */

.friends .user h1{
    font-size: 17px;
    font-weight: 700;
    font-family: 'Satoshi';
    margin: 0;
}

.friends .user p{
    margin:0;
    font-family:'Satoshi';
    font-weight:bold;
    color:var(--border);
    font-size:15px;  
}

.friends .add-friend{
    width:100%;
    display:flex;
    position: relative;
    transition: all 0.5s ease;
    border-radius: 10px;
    border: 1px solid var(--lightborder);
    background-color: var(--background);
}

.friends .add-friend.active{
    transform: scale(1.01);
    z-index: 19;
}

.friends .add-friend .friends-results::-webkit-scrollbar {
    width: 5px;
}

.friends .add-friend .friends-results{
    width:100%;
    display:flex;
    flex-direction: column;
    gap:10px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--light);
    border-radius: 0 0 10px 10px;
    box-shadow: var(--shadow);
    z-index: 19;
    max-height: 200px;
    overflow-y: scroll;
    display:none;
    border-top:10px solid var(--background);
    transform:translateY(-8px);

}

/* .friends .add-friend .friends-results:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--light);
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
} */

.friends .add-friend .friends-results.active{
    display:block;
}

.friends .add-friend button{
    width:50px;
    height:40px;
    border-radius: 0 9px 9px 0;
    background: var(--border);
    border:none;
    color:var(--background);
    font-family:'Inter';
    font-weight:700;
    font-size:16px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    z-index: 19;
}

.friends .add-friend button:hover{
    background: var(--lightborder);
}

.friends .add-friend input{
    width:100%;
    height:40px;
    border-radius: 10px;
    padding:0 10px;
    font-family:'Inter';
    font-weight:bold;
    font-size:16px;
    border:none;
    background-color: var(--background);
    color: var(--text);
    font-weight:500;
    padding-left:40px;
    z-index: 19;
    position: relative; 
}

.friends .add-friend input::placeholder{
    color:var(--border);
}

.friends .add-friend input:focus{
    outline:none;
}

.friends .friends-list{
    width:100%;
    display:flex;
    flex-direction: column;
    border: 1px solid var(--lightborder);
    height:100%;
    border-radius: 15px;
    overflow:hidden;
}

.friends .friends-list-header{
    width:100%;
    box-sizing: border-box;
    padding:10px 15px;
    display:flex;
    border-bottom: 1px solid var(--lightborder);
    gap:10px;
    justify-content: space-between;
    transform: 0.5s ease;
    height:50px;
}


.friends .content-container{
    max-height:calc(100% - 50px);
    display:flex;
    width:200%;
    transition: all 0.5s ease;
    flex-direction: row;
    padding:0;
    align-items: flex-start ;
    overflow: hidden;
    flex:1;
    
}

.friends .overflow-container{
    width:100%;
    height:100%;
}

.friends .content-container.left-staging{
    transform: translateX(-50%);

}

.friends .content{
    transition: all 0.5s ease;
    width:50%;
    padding:10px; 
    display:flex;
    flex-direction: column;
    gap:5px;
    overflow-y:scroll;
    height:100%;
    box-sizing: border-box;
}

.friends .friends-list-header h2{
    font-size: 19px;
    font-weight: 700;
    font-family: 'Satoshi';
    margin: 0;
    color: var(--text);
}

.friends .friends-list-header .pending{
    font-weight:500;
    transform:translateY(1px);
    background-color: var(--light);
    padding:2px 10px;
    border-radius: 6px;
    cursor:pointer;
    display:flex;
    gap:5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.friends .pending-count{
    background-color: var(--red);
    aspect-ratio: 1/1;
    border-radius: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    height:80%; 
}

.friends .pending-count p{
    line-height: 0.7;
    font-size:11px;
    color: var(--background);
}

.friends .friends-list-header .pending:hover{
    background-color: var(--lightborder);
}

.friends .friends-list-header .pending.active{
    background-color: var(--border);
    color:var(--background);    
}

.friends .left-staging{
    transform: translateX(-100%);
}

.friends .right-staging{
    transform: translateX(100%);
}

.friends .no-friends{
    flex-grow:1;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--border);
    font-family: 'Inter';
}

.friends .add-friend-icon{
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:10px;
    z-index: 101;
}

.friends .add-friend-icon img{
    width: 20px;
    height: 20px;
}

.friends .no-requests{
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom:50px;
}

.friends .no-requests p{
    font-family: 'Inter';
    font-weight: 500;    
}