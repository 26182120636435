.full-event{
    display:flex;
    width:calc(100% - 50px);
    height:100%;
    gap:20px;
    position: relative;
    background-color: var(--background);
    border-radius: 20px;
    overflow:hidden;
    padding:20px;
    box-sizing: border-box;
    
    iconify-icon{
        font-size: 20px;
        color: var(--text);
    }

    .image{
        max-width: 35%;
        img{
            width:100%;
            border-radius: 10px;
            box-shadow: var(--shadow);
        }
    }
    .event-content{
        flex-grow: 1;
        padding-top:5px;
        box-sizing: border-box;
        gap:10px;
        display:flex;
        flex-direction: column;
        h1{
            font-size: 25px;
            margin-bottom:0;
        }
        p{
            font-size: 15px;
            font-weight: 500;
            font-family: 'Inter';
        }

    }
    .gradient{
        position: absolute;
        bottom:0;
        right:0;
        height:110px;
        height:30%;
    }
    .row.hosting{
        display: flex;
        align-items: center;
        img{
            width:25px;
            height:25px;
            border-radius: 50%;
            margin-right:5px;
        }
        .user-name{
            font-weight: 700;
            font-size:16px;
            white-space: nowrap;  
            overflow: hidden;    
            text-overflow: ellipsis;
        }
        .level{
            font-size:10px;
            font-weight: 700;
            color: var(--background);
            background-color: var(--green);
            padding:1px 5px;
            border-radius: 5px;
            &.faculty{
                background-color: var(--blue);
            }
            &.organization{
                background-color: var(--red);
            }
            &.developer{
                background-color: var(--developerBlue);
            }
        }
    }
}