.rating-component{
    display:flex;
    width:100%;
    padding:0 20%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:20px;

    h1{
        text-align: center;
        font-size:22px;
        margin:0;
    }

    h2{
        margin:0;
        font-size:22px;
        font-family: 'Inter';
    }
    
    textarea{
        padding: 8px 20px;
        background-color: var(--lighter);
        border: none;
        border-radius: 10px;
        font-family: Inter, sans-serif;
        width: 100%;
        height: 100px;
        box-sizing: border-box;
    }

    textarea:focus{
        outline: none;
    }

    .comment{
        width:100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        gap:10px;

        p{
            font-family: 'Inter';
            font-size: 15px;
            font-weight:500
        }
    }

    .stars{
        display:flex;
        flex-direction: column;
        align-items: center;

    }
    .icon{
        width:25px;
        height:25px;
    }

    .save-button{
        margin-top: 15px;
        padding: 6px 60px;
        background-color: var(--lightborder);
        color: white;
        border: none;
        border-radius: 7px;
        cursor: pointer;
        transition: all 0.3s;
        font-family: Inter, sans-serif;
        font-weight:700;
    }

    .save-button.alt {
        background-color: var(--red);
    }
    
    .save-button.alt:hover {
        filter: brightness(0.9);
    }
}

@media screen and (max-width: 500px){
    .rating-component{
        padding:0 20px;
    }
}