.landing{
    height:100vh;
    width:100vw;
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    b{
        color:var(--red);
    }
}

.hero-picture{
    position: absolute;
    bottom:0;
    right:0;
    width: 100%;
    z-index: 2;
    transition: all 1s;
    transform:translateY(200px);
    opacity: 0;
}

.hero-picture.active{
    transform:translateY(0px);
    opacity: 1;
}

.hero-gradient{
    position: absolute;
    bottom:-800px;
    left:-600px;
    width: 170%;
    z-index: 1;
    transition: all 1s;
    transform:translateY(200px);
    opacity: 0;
}

.hero-gradient.active{
    transform:translateY(0px);
    opacity: 1;
}

.landing .content{
    z-index: 3;
    width:500px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top:150px;
    gap:10px;

}
.landing .content h1{
    text-align: center;
    font-size:2em;
}

.landing .content button{
    font-family: 'Satoshi';
    font-size:2em;
    color: white;
    background-color: var(--red);
    font-weight: bold;
    border:none;
    outline: none;
    padding: 5px 25px;
    border-radius: 15px;
    position: relative;
    display:flex;
    gap:10px;
    align-items: center;
}

.landing .content button img{
    width: 20px;
    height: 20px;
}

.landing .try-button::after{
    transition: all 0.5s;
    content: '';
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  #F2374C 17.8%, #F9E298 100.2% );
    filter: blur(0px);
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.landing .try-button:hover::after {
    filter: blur(20px);
    opacity: 1;
  }

.landing .try-button.active::after {
    filter: blur(20px);
    opacity: 1;
}



@media (max-width:1500px) and (max-height:750px) {
    
    .hero-picture{
        position: absolute;
        bottom:-70px;
        right:0;
    }
}

@media (max-width:1500px) and (max-height:700px) {
    .landing .content{
        transform:translateY(-30px);
    }
    .hero-picture{
        position: absolute;
        bottom:-100px;
        right:0;
    }
}

@media (max-width:1500px) and (max-height:600px) {
    .landing .content{
        transform:translateY(-50px);
    }
    .hero-picture{
        position: absolute;
        bottom:-150px;
        right:0;
    }
}

  
@media (max-width:500px) {
    .landing .content{
        transform:translateY(0px);
    }
    .hero-picture{
        bottom:0;
        right:0;
        width: 300%;
    }
    .hero-gradient{
        bottom:-400px;
        left:-400px;
        width: 200vh;
        z-index: 1;
    }
    .landing .content{
       width:300px;
       margin-top:50px;
    }
    
    .landing .content h1{
        font-size:1.5em;
    }
    
    .landing .content button{
        font-size:1.4em;
        color: white;

    }
}

