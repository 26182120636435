.file-upload{
    background-color: var(--background);
    border: 2px dashed var(--lightborder);
    transition: 0.3s all;
    border-radius: 10px;
    margin-bottom:15px;
    padding:10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top:20px;
    position: relative;

    &.active{
        background-color: var(--translucent);
    }

    iconify-icon{
        font-size: 40px;
        color: var(--lightborder);
        transition: all 0.3s;
    }

    img{
        max-width:80%;
        border-radius: 10px;
        box-shadow: var(--shadow);
    }

    &.drag-over{
        background-color: var(--translucent);
        border: 2px dashed var(--red);
        iconify-icon{
            color: var(--red);
        }
    }
}

.file-upload h3{
    font-family: 'Inter';
    font-weight: 500;
    font-size:15px;
    color: #737373;
    margin:0;
    margin-bottom: 10px;
    margin-top:10px;
}


.file-upload p{
    font-family: 'Inter';
    font-weight: 500;
    font-size:12px;
    color: #737373;
    margin:10px;
}


.file-upload .upload-button{
    font-family: 'Inter';
    font-weight: 600;
    border-radius: 5px;
    padding: 3px 12px;
    background-color: var(--border);
    color: white;
    font-size: 13px;
    transition: all 0.3s;
    border:none;
    outline:none;
}

.file-upload .upload-button:hover{
    background-color: var(--lightborder);
}

.file-upload .upload-button.active{
    background-color: rgba(250,117, 109,0.74);
}

.file-upload .clear{
    position: absolute;
    top:-8px;
    right:-8px;
}