.oie-weekly-calendar-container {
    --border-color: var(--lighterborder);
    --bounding-color: #fbfbfb;
    --hour-border: #e7e7e7;
    --half-hour-border: #f0f0f0;

    --header-selected: #fef0ef;
    --body-selected: #fbeceb;
    --selected-overlay: rgba(250, 117, 109, 0.07);

    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    border-radius: 10px;
    position: relative;

    .calendar-header {
        display: flex;
        position: sticky;
        top: 0;
        z-index: 2;
        border: none;
        background-color: var(--background);
        z-index: 5;
    }

    .time-header {
        width: 60px;
        flex-shrink: 0;
        border: 1px solid var(--border-color);
        border-radius: 10px 0 0 0;
    }

    .current-time-line {
        position: absolute;
        top: 0;
        left: 61px;
        right: 0;
        width: calc(100% - 61px);
        z-index: 1;
        //dotted
        border-radius: 1px;
        border-bottom: 2px dashed var(--lightborder);

    }

    .time-grid-line {
        position: absolute;
        left: 0;
        right: 0;
        // border-top: 1px solid #eee;
        z-index: 0;
    }

    .hour-line {
        border-top: 1px solid var(--hour-border);
        z-index: 3;

        &:first-child {
            border-top: none;
        }
    }

    .half-hour-line {
        border-top: 1px dashed #f0f0f0;
    }

    .day-header {
        flex: 1;
        text-align: center;
        //   uppercase
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: var(--background);
        gap: 5px;
        border: 1px solid var(--border-color);
        padding: 4px 6px;
        box-sizing: border-box;
        border-left: none;
        position: relative;
        z-index: 5;

        .day-name {
            margin: 0;
        }

        .day-name,
        .day-date {
            color: var(--text);
            font-family: "Inter";
            font-weight: 500;
            font-size: 13px;
            text-align: center;
            -webkit-user-select: none;
            user-select: none;
        }

        &:last-child {
            border-radius: 0 10px 0 0;
        }

        &.current-day {
            border-bottom: 1px solid var(--red);
            z-index: 5;

            .day-date {
                color: var(--red);
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--selected-overlay);
            }
        }
    }

    &.day-only .day-header{
        border-radius: 10px 10px 0 0;
        border:1px solid var(--border-color);
        border-left: 1px solid var(--border-color);
    }

    .calendar-body {
        display: flex;
        position: relative;
        min-height: 1440px;
        /* 24 hours * 60 minutes */
    }

    .time-column {
        width: 60px;
        flex-shrink: 0;
        position: relative;
        border-left: 1px solid var(--border-color);
        border-right: 1px solid var(--border-color);
    }

    .time-label {
        position: absolute;
        right: 5px;
        font-size: 0.8em;
        color: #666;
        transform: translateY(-50%);
        background: white;
        padding-right: 5px;
    }

    .days-container {
        display: flex;
        flex: 1;

    }

    .day-column {
        flex: 1;
        border-right: 1px solid #eee;
        position: relative;
        background-color: var(--bounding-color);

        .current-day-time-line{
            position: absolute;
            width:100%;
            border-bottom:2px solid var(--red);
            z-index: 5;
            &::before{
                content: "";
                position:absolute;
                height:7px;
                width:7px;
                left:0;
                top:-3px;
                border-radius: 50%;
                background-color: var(--red);
            }
        }


        &.current-day {
            z-index: 4;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--selected-overlay);
            }
        }
    }

    .days-container .day-column:hover {
        filter:brightness(0.98);
    }



    .event {
        position: absolute;
        margin: 2px;
        z-index: 4;
        padding: 0;
        background-color: transparent;
        opacity: 1;
        &:hover {
            z-index: 5;
        }
    }

    .fixed-bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid var(--border-color);
        z-index: 2;
        height: 10px;
        border-radius: 10px;
        background-color: transparent;
    }

    //scrollbar
    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--dark);
        width: 5px;
        border: 4px solid var(--background);
    }

    &::after {
        content: "";
        position: fixed;
        bottom: 1px;
        width: 100%;
        height: 1px;
        background-color: var(--border-color);
    }
}